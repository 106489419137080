import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import rootRoutes from "./Components/Routes/RootRoutes";
// import Login from './Components/IAMS/Login/Login';
// import ForgotCredientials from './Components/IAMS/ForgotCredientials/ForgotCredientials';
// import Acknowledgement from './Components/IAMS/acknowledgement/acknowledgement';
// import SetPassword from './Components/IAMS/SetPassword/SetPassword';
// import HomePage from './Components/HomePage/HomePage';
// import GodPage from './Components/GodMode/GodPage/GodPage';
// import EditClientMainDetails from './Components/HomePage/Masters/Client/EditClientMainDetails';
// import EditClientKnowYourClientsDetails from './Components/HomePage/Masters/Client/EditClientKnowYourClientsDetails';
// import ViewPatient from './Components/HomePage/Masters/Patients/ViewPatient';
// import EditPatientMainDetails from './Components/HomePage/Masters/Patients/EditPatientMainDetails';
// import EditPatientDocumentDetails from './Components/HomePage/Masters/Patients/EditPatientDocumentDetails';
// import AccountBlocked from './Components/IAMS/AccountBlocked';
const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<Login />}></Route>
          <Route path='/ForgotCredientials' element={<ForgotCredientials />}></Route>
          <Route path='/acknowledgement' element={<Acknowledgement />}></Route>
          <Route path='/accountBlocked' element={<AccountBlocked/>}></Route>
          <Route path='/SetPassword' element={<SetPassword />}></Route>
          <Route path='/HomePage/*' element={<HomePage />}></Route>
          <Route path='/GodPage/*' element={<GodPage />}></Route>
          <Route path='/EditClientMainDetails' element={<EditClientMainDetails/>}></Route>
          <Route path='/EditClientKnowYourClientsDetails' element={<EditClientKnowYourClientsDetails/>}></Route>
          {/* <Route path='/ViewPatient' element={<ViewPatient/>}></Route> */}
          {/* <Route path='/EditPatientMainDetails' element={<EditPatientMainDetails/>}></Route>
          <Route path='/EditPatientDocumentDetails' element={<EditPatientDocumentDetails/>}></Route> */}
          {rootRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
