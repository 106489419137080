import React from "react";

const StockDetails = (props) => {
  document.querySelectorAll(".dropdown").forEach((dropdown) => {
    dropdown.addEventListener("show.bs.dropdown", () => {
      dropdown.querySelector(".stockDropdown").classList.add("isInfoPanelActive");
    });
    dropdown.addEventListener("hide.bs.dropdown", () => {
      dropdown.querySelector(".stockDropdown").classList.remove("isInfoPanelActive");
    });
  });
  
  return (
    <div>
      <div className={ `dropdown me-3 btn-group ${props.displayType}` }>
        <ul className="dropdown-menu stockDropdownMenu">
          <div className="d-flex flex-row flex-wrap">
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
            <div className="stockDropdownItem p-0 d-flex flex-row align-items-center">
              <div className="count d-flex align-items-center">430</div>
              <div className="countTitle px-2">abcd</div>
            </div>
          </div>
        </ul>
        <button
          type="button"
          className="text-start stockDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          title="Stock Overview by Brand"
          style={{width: props.buttonwidth}}
        >
          <span className="px-3 py-2 d-flex align-items-center justify-content-between">
            {props.dropdownTitle}
          </span>
        </button>
      </div>
    </div>
  );
};

export default StockDetails;
