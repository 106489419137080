import React, { useState, useEffect } from "react";
import "./GodPage.css";
import img_optolinqName from "../../../Assets/OPTO LINQ_1080.png";
import optolinqlogo from "../../../Assets/OptoIcon.ico";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pillp from "../../../Assets/image.png";
import { useLocation } from "react-router-dom";
import { GodpageRoutes } from "../../Routes/OptoRoute";
import {
  faBars,
  faEarthAfrica,
  faBell,
  faArrowRightFromBracket,
  faClose,
  faHome,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Route, Routes } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const GodPage = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeLink, setActiveLink] = useState("");

  const handleLinkClick = () => {
    setIsCollapsed(true);
  };
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const isActive = (path) => {
    return location.pathname === path ? "isActive" : "";
  };
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  console.log(pathnames)
  const breadcrumbNames = GodpageRoutes.reduce((acc, route) => {
    acc[route.path] = route.name.split(/(?<!^)(?=[A-Z])/).join(" ");
    console.log(acc);
    return acc;
  }, {});
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
      {props}
    </Tooltip>
  );

  return (
    <div className="d-flex flex-row">
      {/* Sidebar*/}
      <div
        className={`sidebar d-flex flex-column justify-content-between fixed-top bg-white vh-100 ${
          isCollapsed ? "collapsed" : ""
        }`}
        style={{ minWidth: isCollapsed ? "80px" : "250px" }}
      >
        <div className="p-3 sidebar-top-content" style={{ height: "50px" }}>
          {isCollapsed ? (
            <img
              src={optolinqlogo}
              className="logo2"
              alt="logo2"
              style={{ width: "auto", height: "40px" }}
            />
          ) : (
            <img
              src={img_optolinqName}
              className="logo"
              alt="logo"
              style={{ width: "100%", height: "auto" }}
            />
          )}
          <ul className="nav flex-column">
            <li
              className={`nav-item  ${isCollapsed ? "mx-auto" : ""} ${isActive(
                "/GodPage"
              )}`}
            >
              <Link
                to={"/GodPage"}
                className="nav-link  text-black p-2 d-flex align-items-center"
                onClick={handleLinkClick}
              >
                <FontAwesomeIcon
                  className="sidebaricon"
                  icon={faHome}
                  style={{ color: "#717171", width: "25px", height: "auto" }}
                />
                <span>Dashboard</span>
              </Link>
            </li>
            <li
              className={`nav-item  ${isCollapsed ? "mx-auto" : ""} ${isActive(
                "/GodPage/Company"
              )}`}
            >
              <OverlayTrigger
                placement="right"
                overlay={renderTooltip("Company")}
              >
                <Link
                  to={"/GodPage/Company"}
                  className="appiontmentHover nav-link p-2 text-black d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    className="sidebaricon"
                    icon={faEarthAfrica}
                    style={{
                      color: "#717171",
                      width: "25px",
                      height: "auto",
                    }}
                  />
                  <span>Company</span>
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        <div
          className="pillp-container text-center mb-3"
          style={{ opacity: "50%" }}
        >
          <img
            src={pillp}
            className="pillp-logo"
            alt="pillp logo"
            style={{ width: "auto", height: "40px" }}
            data-bs-toggle-tooltip="tooltip"
            title="pillp"
          />
          <p style={{ fontSize: "12px" }}>V 1.0.0</p>
          <div className="copyright-info d-flex align-items-center flex-row justify-content-center">
            <FontAwesomeIcon
              className="sidebaricon"
              icon={faCopyright}
              style={{ color: "#717171", width: "12px", height: "auto" }}
            />
            <p style={{ fontSize: "10px" }}>2024-25</p>
          </div>

          {isCollapsed ? (
            ""
          ) : (
            <div className="session-info d-flex flex-column">
              <p style={{ fontSize: "10px" }}>Current Login</p>
              <p style={{ fontSize: "10px" }}>Last Login</p>
            </div>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-grow-1 d-flex flex-column" id="mainContent">
        <nav className="navbar bg-body-tertiary d-flex flex-row justify-content-between align-items-center sticky-top">
          <div className="div1 d-flex align-items-center">
            <img
              src={optolinqlogo}
              className="logo2-navbar"
              alt="logo2"
              style={{ width: "auto", height: "40px" }}
            />
            <FontAwesomeIcon
              icon={faBars}
              onClick={toggleSidebar}
              className="menu mr-3 space-right2 cursor-pointer"
              style={{ color: "#4CC8F7", width: "20px", height: "auto" }}
            />
            <nav className="d-flex align-items-center" aria-label="breadcrumb">
              <ol className="breadcrumb d-flex align-items-center">
                {pathnames.map((value, index) => {
                  let to = `/${pathnames.slice(0, index + 1).join("/")}`;
                  console.log(to);

                  return (
                    <li key={to} className="breadcrumb-item">
                      <Link to={to}>
                        {breadcrumbNames[`/${value}`]}
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </nav>
          </div>
          <div className="div2 d-flex align-items-center justify-content-between">
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip("Notifications")}
            >
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faBell}
                style={{
                  color: "#ffffff",
                  width: "25px",
                  height: "auto",
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={renderTooltip("Logout")}
            >
              <FontAwesomeIcon
                className="navbaricon mx-3"
                icon={faArrowRightFromBracket}
                style={{
                  color: "#ffffff",
                  width: "25px",
                  height: "auto",
                }}
              />
            </OverlayTrigger>
          </div>
        </nav>

        <div className="innerContent flex-grow-1 ">
          {/* Form Card and its results */}
          <div className="formCard flex-grow-1 ">
            <Routes>
              {GodpageRoutes.map((route, idx) => {
                const Component = route.component; // Store component in a variable
                return (
                  <Route
                    key={idx} // It's important to give each route a unique key
                    path={route.path}
                    element={<Component />} // Render component as JSX
                  />
                );
              })}
            </Routes>
          </div>
        </div>
        {/* Main Content Area */}
      </div>
    </div>
  );
};

export default GodPage;
