import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditSuppliersContactDetails = ({ open }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_physicalAddress: '',
    tb_postBox: '',
    tb_city: '',
    tb_primaryContact: '',
    tb_secondaryContact: '',
    tb_emailAddress: '',
    tb_contactName: '',
    tb_contactdesignation: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/SUPPLIER/editSupplierContactHandler.php',
        // data: params,
        data: {
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_contactName': formData.tb_contactName,
          'tb_postBox': formData.tb_postBox,
          'tb_city': formData.tb_city,
          'tb_contactdesignation': formData.tb_contactdesignation,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/Homepage/ViewSuppliers`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick>
        <div className="editPopup"  >
        <div className="row">
                <div className="col-md-12">
                  <label htmlFor="tb_physicalAddress" className="">
                    Physical Address
                  </label>
                  <input
                    type="text"
                    id="tb_physicalAddress"
                    name="tb_physicalAddress"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_postBox" className="">
                    P.O.Box
                  </label>
                  <input
                    type="text"
                    id="tb_postBox"
                    name="tb_postBox"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_city" className="">
                    City
                  </label>
                  <input
                    type="text"
                    id="tb_city"
                    name="tb_city"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_primaryContact" className="">
                    Primary Contact
                  </label>
                  <input
                    type="text"
                    id="tb_primaryContact"
                    name="tb_primaryContact"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_secondaryContact" className="">
                    Secondary Contact
                  </label>
                  <input
                    type="text"
                    id="tb_secondaryContact"
                    name="tb_secondaryContact"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor="tb_emailAddress" className="">
                    Email Address
                  </label>
                  <input
                    type="text"
                    id="tb_emailAddress"
                    name="tb_emailAddress"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_contactPerson" className="">
                    Contact Person
                  </label>
                  <input
                    type="text"
                    id="tb_contactPerson"
                    name="tb_contactPerson"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_designation" className="">
                    Designation
                  </label>
                  <input
                    type="text"
                    id="tb_designation"
                    name="tb_designation"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
                </div>
                </div>
                <br/>
                <div className="row btnGroup">
                <div className="col-md-12 ">
                  <button className="btnCTA btnAddSave col-6" onClick={handleSubmit}>
                    Save
                  </button>
                </div>
              </div>
        </div>
      </Popup>
    </div>
  );
};

export default EditSuppliersContactDetails;
