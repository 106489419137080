import React, { useEffect,useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../optoCustom.js";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const SearchVouchers = () => {
  const [formData, setFormData] = useState({
    dp_voucherDate: "",
    db_paidToName: "",
    db_currencyName: "",
    db_ledgerName: "",
    db_paymentType: "",
  });

  const [clientData, setClientData] = useState([]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/CLIENT/filterClientHandler.php",
        data: {
            dp_voucherDate: formData.dp_voucherDate,
            db_paidToName: formData.db_paidToName,
            db_currencyName: formData.db_currencyName,
            db_ledgerName: formData.db_ledgerName,
            db_paymentType: formData.db_paymentType,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setClientData(response.data.OPTO[0].OPTO_MESSAGE);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else {
            toast.error("Network Error", { position: "top-center" });
          }
        });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  const handleReset = () => {
    setFormData({
        db_voucherDate: "",
        db_paidToName: "",
        db_currencyName: "",
        db_ledgerName: "",
        db_paymentType: "",
    });
    setClientData([]);
  };

  const columns = [
    {
      name: "Voucher Id",
      selector: (row) => row.client_name,
      sortable: true,
    },
    {
      name: "Paid To Name",
      selector: (row) => row.primary_number,
    },
    {
      name: "Voucher Name",
      selector: (row) => row.secondary_number,
    },
    {
      name: "Payment Date",
      selector: (row) => row.email_address,
    },
    {
      name: "Payment Type",
      selector: (row) => row.physical_address,
    },
    {
      name: "View",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/HomePage/ViewClient/${row.client_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
  };

  return (
    <div>
      <ToastContainer />
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
          <div className="formGroup col-4 formGroup">
            <label htmlFor="dp_voucherDate" className="">
             Voucher Date 
            </label>
            <input
              className="form-control formControl"
              type="date"
              id="dp_voucherDate"
              name="dp_voucherDate"
              max={today}
              // value={dob}
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-4">
            <label htmlFor="db_ledgerName" className="">
              Ledger Name
            </label>
            <Select
            //   options={options_xxxx}
              placeholder="Ledger Name"
              id="db_ledgerName"
              name="db_ledgerName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable={false}
              isClearable
              onChange={handleSingleSelect}
            />
          </div>
          <div className="formGroup col-4">
            <label htmlFor="db_currencyName" className="">
              Currency Name
            </label>
            <Select
            //   options={options_xxxx}
              placeholder="Currency Name"
              id="db_currencyName"
              name="db_currencyName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable={false}
              isClearable
              onChange={handleSingleSelect}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="db_paidToName" className="">
              Paid to Name
            </label>
            <Select
            //   options={options_xxxx}
              placeholder="Paid to Name"
              id="db_paidToName"
              name="db_paidToName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable={false}
              isClearable
              onChange={handleSingleSelect}
            />
          </div>
          <div className="col-3 formGroup">
              <label htmlFor="tb_transactionId" className="">
                Transaction Id
              </label>
              <input
                type="text"
                id="tb_transactionId"
                name="tb_transactionId"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_emailAddress}
                onChange={handleChange}
              />
            </div>
          <div className="formGroup col-3">
            <label htmlFor="db_paymentType" className="">
              Payment Type
            </label>
            <Select
            //   options={options_xxxx}
              placeholder="Payment Type"
              id="db_paymentType"
              name="db_paymentType"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable={false}
              isClearable
              onChange={handleSingleSelect}
            />
          </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button type="reset" className="btnCTA btnReset" onClick={handleReset}>
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA  btnFilter">
                Filter
              </button>
            </div>
            <div className="col-md-4">
              <Link to={"/Homepage/AddVouchers"}>
                <button type="button" className="btnCTA btnAddSave">
                  Add Voucher
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      {/* DataTable to display client data */}
      <DataTable
        className="resultData"
        columns={columns}
        data={clientData} // Data received from API
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      />
    </div>
  );
};

export default SearchVouchers;
