import React, {  useEffect,useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom.js';
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const SearchSuppliers = () => {
  const [formData, setFormData] = useState([]);
  const [supplierData, setsupplierData] = useState([]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleReset = () => {
      setFormData({
          tb_supplier: "",
          tb_primaryNumber: "",
          tb_secondaryNumber: "",
          tb_emailAddress: "",
          tb_physicalAddress: "",
          tb_poBox:"",
      });
      setsupplierData([]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/SUPPLIER/filterSupplierHandler.php',
        // data: params,
        data: {
          'tb_supplier': formData.tb_supplier,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_poBox': formData.tb_poBox,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then((response) => {
        if (response.status === 200 && response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS") {
            // Log response to check the data
            console.log(response.data.OPTO[0].OPTO_MESSAGE);
            // Set supplierData to an array
            setsupplierData(response.data.OPTO[0].OPTO_MESSAGE || []);
        }
      }).catch((error) => {
        if (error.response) {
          toast.error(error.response.data['error'] || error.message, { position: "top-center" });
        } else {
          toast.error('Network Error', { position: "top-center" });
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };
  

  const columns = [
    {
      name: "Supplier Name",
      selector: (row) => row.supplier_name,
      sortable: true,
    },
    {
      name: "Primary Contact Number",
      selector: (row) => row.primary_number,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) => row.email_address,
      sortable: true,
    },

    {
      name: "View",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/HomePage/ViewSuppliers/${row.supplier_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];


const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };


  return (
    <div>
      <ToastContainer/>
      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* String Fields */}
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_supplier" className="">
                Supplier Name
              </label>
              <input
                type="text"
                id="tb_supplier"
                name="tb_supplier"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6  formGroup">
              <label htmlFor="tb_primaryNumber" className="">
                Primary Contact
              </label>
              <input
                type="text"
                id="tb_primaryNumber"
                name="tb_primaryNumber"
                className="inputBox form-control formControl  text-dark"
                maxLength="12"
                onChange={handleChange}
              />
            </div>
            <div className="col-6  formGroup">
              <label htmlFor="tb_emailAddress" className="">
                Email Address
              </label>
              <input
                type="email"
                id="tb_emailAddress"
                name="tb_emailAddress"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA btnFilter" onClick={handleSubmit}>
                Filter
              </button>
            </div>
            <div className="col-4">
              <Link to={"/Homepage/AddSuppliers"}>
                <button type="button" className="btnCTA btnAddSave">
                  Add New Supplier
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <DataTable
        className="resultData"
        columns={columns}
        data={supplierData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
    </div>
  );
};

export default SearchSuppliers;
