import React from "react";
import "./acknowledgement.css";
import img_optolinqName from "../../../Assets/OPTO LINQ.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Acknowledgement() {
  return (
    <div>
      <Container className="cont_login d-flex justify-content-center align-items-center min-vh-100">
        <Card className="p-4" style={{ maxWidth: "400px", width: "100%" }}>
          <Card.Body>
            <br />
            <div className="img_optolinqName text-center mb-3">
              <img
                src={img_optolinqName}
                alt="logo"
                style={{ width: "95%", height: "auto" }}
              />
            </div>
            <Form className="frm_login mt-3">
              <div className="text-center mt-4 name" id="sub-heading">
                A recovery email has been shared with you. If you have not
                recieved it, kindly contact your administrator.
              </div>
              <div className="d-flex align-items-center">
                <Button
                  type="submit"
                  id="btn_login"
                  name="btn_login"
                  className="btn_login mt-3 w-100 d-flex justify-content-center align-items-center mx-auto"
                  as={Link}
                  to={"/"}
                >
                  Back to Sign-In
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
