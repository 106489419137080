import React from "react";
import DataTable from "react-data-table-component";
import "./View.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../optoCustom";
import AddPatient from "../../HomePage/Masters/Patients/AddPatient";
import Popup from "../../GodMode/Edit Pages/EditMainDetails";
import PopupBr from "../../GodMode/Edit Pages/EditBranchDetails";
import PopupSMTP from "../../GodMode/Edit Pages/EditSMTPDetails";
import PopupDocs from "../../GodMode/Edit Pages/EditDocumentsDetails";
import PopupDigital from "../../GodMode/Edit Pages/EditDigitalDetails";
import PopupStaff from "../../GodMode/Edit Pages/EditStaffDetails";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const View = () => {
  const { companyId } = useParams();
  console.log("Company id From Search : " + companyId);
  const [formData, setFormData] = useState([]);
  const [branchDetails, setbranchDetails] = useState([]);
  const [mainDetails, setmainDetails] = useState([false]);
  const [smtpDetails, setsmtpDetails] = useState([]);
  const [digitalDetails, setdigitalDetails] = useState([]);
  const [staffDetails, setstaffDetails] = useState([]);
  const [documentDetails, setdocumentDetails] = useState([]);
  const [historyDetails, sethistoryDetails] = useState([]);
  const [mainIsOpen, setmainIsOpen] = useState(false);
  const [branchIsOpen, setbranchIsOpen] = useState(false);
  const [SMTPIsOpen, setSMTPIsOpen] = useState(false);
  const [DocsIsOpen, setDocsIsOpen] = useState(false);
  const [DigitalIsOpen, setDigitalIsOpen] = useState(false);
  const [StaffIsOpen, setStaffIsOpen] = useState(false);
  const toggleMainPopup = () => {
    setmainIsOpen(!mainIsOpen);
  };
  const toggleBranchPopup = () => {
    setbranchIsOpen(!branchIsOpen);
  };
  const toggleSMTPPopup = () => {
    setSMTPIsOpen(!SMTPIsOpen);
  };
  const toggleDocsPopup = () => {
    setDocsIsOpen(!DocsIsOpen);
  };
  const toggleDigitalPopup = () => {
    setDigitalIsOpen(!DigitalIsOpen);
  };
  const toggleStaffPopup = () => {
    setStaffIsOpen(!StaffIsOpen);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.post(
        //   method: 'post',
        //   API_BASE_URL + 'MASTER/CLIENT/viewClientDetailsHandler.php',
        //   data: formData,
        // );
        axios({
          method: "post",
          url: API_BASE_URL + "GODMODE/companyViewHandler.php",
          data: {
            tb_companyId: companyId,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        })
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
            ) {
              // Extract the OPTO_MESSAGE array and set it to the state
              // setClientData(response.data.OPTO[0].OPTO_MESSAGE);
              console.log(response.data.OPTO[0].OPTO_MESSAGE);
              const responsedData = response.data.OPTO[0].OPTO_MESSAGE;
              setFormData(responsedData);
              setbranchDetails(responsedData.branch_details || []);
              setmainDetails(responsedData.main_details [0]  || []);
              setsmtpDetails(responsedData.smtp_details [0]  || []);
              setdigitalDetails(responsedData.digital_details  || []);
              setstaffDetails(responsedData.staff_details);
              setdocumentDetails(responsedData.document_details);
              sethistoryDetails(responsedData.history_details || []);
              // toast.success("Clients retrieved successfully!", { position: "top-center" });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                toast.error(error.response.data["error"], {
                  position: "top-center",
                });
              } else {
                toast.error(error.message, { position: "top-center" });
              }
            } else {
              toast.error("Network Error", { position: "top-center" });
            }
          });
      } catch (error) {
        console.error('Error:', error);
        toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
      }
    };

    fetchData();
  }, []);

  const branchAccordionColumns = [
    {
      name: "Branch Name",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.branch_name || ""}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "Branch Code",
      selector: (row) => (
        <input
          type="text"
          className="form-control"
          value={row.branch_code || ""}
          readOnly
        />
      ),
    },
    {
      name: "Contact Number",
      selector: (row) => (
        <input
          type="number"
          className="form-control"
          value={row.contact_number || ""}
          readOnly
        />
      ),
    },
    {
      name: "Email Address",
      selector: (row) => (
        <input
          type="email"
          className="form-control"
          value={row.contact_email_address || ""}
          readOnly
        />
      ),
    },
    {
      name: "Contact Name",
      cell: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.contact_name}
          readOnly
        />
      ),
    },
    {
      name: "Physical Address",
      cell: (row, index) => (
        <textarea
          type="textarea"
          className="form-control"
          value={row.physical_address}
          readOnly
        />
      ),
    },
  ];

  const documentAccordionColumns = [
    {
      name: "Document Name",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.Documents.document_user_name}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "Company Document Id",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.Documents.document_company_document_id}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "Document path",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.Documents.document_path}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "Document status",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.Documents.active_status}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "",
      selector: (row, index) => <button className="btnCTA">Some Action</button>,
    },
  ];

  const digitalAccordionColumns = [
    {
      name: "Company Logo Name",
      selector: (row) => row.company_logo_name,
    },
    {
      name: "Company Logo path",
      selector: (row) => row.company_logo_path,
    },
    {
      name: "Company Logo Path",
      selector: (row) => row.company_logo_path,
    },
    {
      name: "Company Stamp Path",
      selector: (row) => row.company_stamp_path,
    },
    {
      name: "Company Stamp Name",
      selector: (row) => row.company_stamp_name,
    },
  ];

  const historyAccordionColumns = [
    {
      name: "some column",
    },
  ];

  const staffAccordionColumns = [
    {
      name: "Staff Name",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.staff_name}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "Staff ID",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.staff_id}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          value={row.email_address}
          readOnly
        />
      ),
      sortable: true,
    },
  ];

  const paymentRecordsAccordionColumns = [
    {
      name: "column1",
    },
    {
      name: "column1",
    },
    {
      name: "column1",
    },
  ];

  return (
    <div className="viewFormContainer row">
      <ToastContainer/>
      <div className="accordion col-12" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Main
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="col-md-12 mt-1">
                <label htmlFor="tb_companyName" className="">
                  Company Name
                </label>
                <input
                  type="text"
                  id="tb_companyName"
                  name="tb_companyName"
                  className="inputBox form-control text-dark"
                  value={mainDetails.company_name || ""}
                  readOnly
                />
              </div>

              <div className="row mt-1">
                <div className="col-md-4">
                  <label htmlFor="tb_companyShortName" className="">
                    Company Short Name
                  </label>
                  <input
                    type="text"
                    id="tb_companyShortName"
                    name="tb_companyShortName"
                    className="inputBox form-control text-dark"
                    value={mainDetails.company_short_name || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="tb_companySmallName" className="">
                    Company Small Name
                  </label>
                  <input
                    type="text"
                    id="tb_companySmallName"
                    name="tb_companySmallName"
                    className="inputBox form-control text-dark"
                    value={mainDetails.company_small_name || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="tb_lastPaymentDone" className="">
                    Last Payment
                  </label>
                  <input
                    type="text"
                    id="tb_lastPaymentDone"
                    name="tb_lastPaymentDone"
                    className="inputBox form-control text-dark"
                    value={mainDetails.tin || ""}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-12 mt-1">
                <label htmlFor="tb_companyURL" className="">
                  Company URL
                </label>
                <input
                  type="text"
                  id="tb_companyURL"
                  name="tb_companyURL"
                  className="inputBox form-control text-dark"
                  value={mainDetails.company_url || ""}
                  readOnly
                />
              </div>

              <div className="row mt-1">
                <div className="col-md-3">
                  <label htmlFor="tb_licenseNumber" className="">
                    License Count
                  </label>
                  <input
                    type="text"
                    id="tb_licenseNumber"
                    name="tb_licenseNumber"
                    className="inputBox form-control text-dark"
                    value={mainDetails.license_number || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="tb_paymentCycle" className="">
                    Payment Cycle Type
                  </label>
                  <input
                    type="text"
                    id="tb_paymentCycle"
                    name="tb_paymentCycle"
                    className="inputBox form-control text-dark"
                    value={mainDetails.payment_cycle || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-3">
                  <label htmlFor="tb_expectedDate" className="">
                    Expected Date
                  </label>
                  <input
                    type="text"
                    id="tb_expectedDate"
                    name="tb_expectedDate"
                    className="inputBox form-control text-dark"
                    value={mainDetails.expected_payment_date || ""}
                    readOnly
                  />
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-6">
                  <label
                    htmlFor="createdBySelect1"
                    className=" form_label"
                  >
                    Created by
                  </label>
                  <input
                    type="text"
                    id="tb_createdBy"
                    name="tb_createdBy"
                    className="inputBox form-control text-dark"
                    value={mainDetails.created_by || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="lastUpdate" className=" form_label">
                    Last Update By
                  </label>
                  <input
                    type="text"
                    id="tb_lastUpdatedBy"
                    name="tb_lastUpdatedBy"
                    className="inputBox form-control text-dark"
                    value={mainDetails.updated_by || ""}
                    readOnly
                  />
                  <br/>
                </div>
                <button className="btn_edit" onClick={toggleMainPopup}>
                  {" "}
                  Edit
                </button>
                <Popup
                  id="mainDetailspopup"
                  open={mainIsOpen}
                  onClose={toggleMainPopup}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item ">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Branch
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="row">
                <DataTable
                  className="col-12"
                  columns={branchAccordionColumns}
                  data={branchDetails}
                  pagination
                  responsive
                  id="branchAccordionTable"
                ></DataTable>
                <button className="btn_edit col-12" onClick={toggleBranchPopup}>
                  Edit
                </button>
                <PopupBr
                  id="brnachDetailspopup"
                  open={branchIsOpen}
                  onClose={toggleBranchPopup}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              SMTP
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="col-md-12 mt-1">
                <label htmlFor="host" className="">
                  Host
                </label>
                <input
                  type="text"
                  id="tb_host"
                  name="tb_host"
                  className="inputBox form-control text-dark"
                  value={(smtpDetails.smtp_host || "")}
                  readOnly
                />
              </div>

              <div className="row mt-1">
                <div className="col-md-6">
                  <label htmlFor="email" className="">
                    Email
                  </label>
                  <input
                    type="text"
                    id="tb_email"
                    name="tb_email"
                    className="inputBox form-control text-dark"
                    value={smtpDetails.smtp_email_address || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="password" className="">
                    Password
                  </label>
                  <input
                    type="text"
                    id="tb_password"
                    name="tb_password"
                    className="inputBox form-control text-dark"
                    value={smtpDetails.smtp_password || ""}
                    readOnly
                  />
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-5">
                  <label htmlFor="port" className="">
                    Port
                  </label>
                  <input
                    type="text"
                    id="tb_port"
                    name="tb_port"
                    className="inputBox form-control text-dark"
                    value={smtpDetails.smtp_port || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="secure" className="">
                    Secure Type
                  </label>
                  <input
                    type="text"
                    id="tb_secureType"
                    name="tb_secureType"
                    className="inputBox form-control text-dark"
                    value={smtpDetails.smtp_secure || ""}
                    readOnly
                  />
                </div>
                <div className="col-md-5 mt-1">
                  <label htmlFor="name" className="">
                    From Email Name
                  </label>
                  <input
                    type="text"
                    id="tb_fromEmailName"
                    name="tb_fromEmailName"
                    className="inputBox form-control text-dark"
                    value={smtpDetails.smtp_from_email|| ""}
                    readOnly
                  />
                </div>
                <button className="btn_edit" onClick={toggleSMTPPopup}>
                  {" "}
                  Edit
                </button>
                <PopupSMTP
                  id="SMTPDetailspopup"
                  open={SMTPIsOpen}
                  onClose={toggleSMTPPopup}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Document
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={documentAccordionColumns}
                    data={documentDetails}
                    pagination
                    responsive
                    id="documentAccordionTable"
                  ></DataTable>
                  <button className="btn_edit col-12" onClick={toggleDocsPopup}>
                    {" "}
                    Edit
                  </button>
                  <PopupDocs
                    id="DocsDetailspopup"
                    open={DocsIsOpen}
                    onClose={toggleDocsPopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              Digital
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={digitalAccordionColumns}
                    data={digitalDetails}
                    pagination
                    responsive
                    id="digitalAccordionTable"
                  ></DataTable>
                  <button
                    className="btn_edit col-12"
                    onClick={toggleDigitalPopup}
                  >
                    {" "}
                    Edit
                  </button>
                  <PopupDigital
                    id="DocsDetailspopup"
                    open={DigitalIsOpen}
                    onClose={toggleDigitalPopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              History
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse accordion"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={historyAccordionColumns}
                    // data={formData}
                    pagination
                    responsive
                    id="historyAccordionTable"
                  ></DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              Staff
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={staffAccordionColumns}
                    data={staffDetails}
                    pagination
                    responsive
                    id="staffAccordionTable"
                  ></DataTable>
                  <button
                    className="btn_edit col-12"
                    onClick={toggleStaffPopup}
                  >
                    {" "}
                    Edit
                  </button>
                  <PopupStaff
                    id="StaffDetailspopup"
                    open={StaffIsOpen}
                    onClose={toggleStaffPopup}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingEight">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              Payment records
            </button>
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="accordion-body">
                <div className="row">
                  <DataTable
                    className="col-12"
                    columns={paymentRecordsAccordionColumns}
                    // data={formData}
                    responsive
                    id="paymentRecordsAccordionTable"
                  ></DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
