import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from 'axios';
import './Edit.css';
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditMainDetails = ({ open }) => {
  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_companyName: '',
    tb_companyShortName: '',
    tb_companySmallName: '',
    tb_tin: '',
    tb_vrn: '',
    tb_companyURL: '',
    tb_paymentCycle: '',
    tb_licenseCount: '',
    tb_expectedDate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'GODMODE/editMainDetailsHandler.php',
        // data: params,
        data: {
          'tb_companyName': formData.tb_companyName,
          'tb_companyShortName': formData.tb_companyShortName,
          'tb_companySmallName': formData.tb_companySmallName,
          'tb_tin': formData.tb_tin,
          'tb_vrn': formData.tb_vrn,
          'tb_companyURL': formData.tb_companyURL,
          'tb_paymentCycle': formData.tb_paymentCycle,
          'tb_licenseCount': formData.tb_licenseCount,
          'tb_expectedDate': formData.tb_expectedDate,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/GodPage/View`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick>
        <div className='popup'>
          <div className="col-12 mt-1">
            <label htmlFor="tb_companyName" className="">
              Company Name
            </label>
            <input
              type="text"
              id="tb_companyName"
              name="companyName"
              className="inputBox form-control formControl text-dark"
              value={formData.tb_companyName}
              onChange={handleInputChange}
            />
          </div>

          <div className="row mt-1">
            <div className="col-4 mt-1">
              <label htmlFor="tb_companyShortName" className="">
                Company Short Name
              </label>
              <input
                type="text"
                id="tb_companyShortName"
                name="tbcompanyShortName"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_companyShortName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-4 mt-1">
              <label htmlFor="tb_companySmallName" className="">
                Company Small Name
              </label>
              <input
                type="text"
                id="tb_companySmallName"
                name="tb_companySmallName"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_companySmallName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-2 mt-1">
              <label htmlFor="tb_tin" className="">
                TIN
              </label>
              <input
                type="text"
                id="tb_tin"
                name="tb_tin"
                className="inputBox form-control formControl text-dark"
                value={formData.tin}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-2 mt-1">
              <label htmlFor="tb_vrn" className="">
                VRN
              </label>
              <input
                type="text"
                id="tb_vrn"
                name="tb_vrn"
                className="inputBox form-control formControl text-dark"
                value={formData.vrn}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-12 mt-1">
            <label htmlFor="tb_companyURL" className="">
              Company URL
            </label>
            <input
              type="text"
              id="tb_companyURL"
              name="tb_companyURL"
              className="inputBox form-control formControl text-dark"
              value={formData.tb_companyURL}
              onChange={handleInputChange}
            />
          </div>

          <div className="row mt-1">
            <div className="col-md-6">
              <label htmlFor="tb_paymentCycle" className="">
                Payment Cycle Type
              </label>
              <input
                type="text"
                id="tb_paymentCycle"
                name="tb_paymentCycle"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_paymentCycle}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="tb_licenseCount" className="">
                License Count
              </label>
              <input
                type="text"
                id="tb_licenseCount"
                name="tb_licenseCount"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_licenseCount}
                onChange={handleInputChange}
                readOnly
              />
            </div>
            <div className="col-md-3">
              <label htmlFor="tb_expectedDate" className="">
                Expected Date
              </label>
              <input
                type="text"
                id="tb_expectedDate"
                name="tb_expectedDate"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_expectedDate}
                onChange={handleInputChange}
                readOnly
              />
            </div>
          </div>
        </div>

        <br />
        <div className="row btnGroup">
          <div className="col-md-6 ">
            <button type="submit" className="btnCTA" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-md-6 ">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default EditMainDetails;
