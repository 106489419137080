import React from "react";
import Eye from "../../../Assets/Eye.svg";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Edit.css";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditBranchDetails = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [formData, setData] = useState([]);
  const [branchDetails, setBranchDetails] = useState({
    tb_branchName: "",
    tb_branchCode: "",
    tb_contactNumber: "",
    tb_emailAddress: "",
    tb_contactName: "",
    tb_physicalAddress: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get("https://jsonplaceholder.typicode.com/users")
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBranchDetails({ ...branchDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'GODMODE/editBranchDetailsHandler.php',
        // data: params,
        data: {
          'tb_branchName': formData.tb_branchName,
          'tb_branchCode': formData.tb_branchCode,
          'tb_contactNumber': formData.tb_contactNumber,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_contactName': formData.tb_contactName,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/GodPage/View`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  const columns = [
    {
      name: "Branch Name",
      selector: (row, index) => (
        <input
          type="text"
          className="form-control"
          // value={row.branch_name || ""}
          readOnly
        />
      ),
      sortable: true,
    },
    {
      name: "Branch Code",
      selector: (row) => (
        <input
          type="text"
          className="form-control"
          // value={row.branch_code || ""}
          readOnly
        />
      ),
    },
    {
      name: "Contact Number",
      selector: (row) => (
        <input
          type="number"
          className="form-control"
          // value={row.contact_number || ""}
          readOnly
        />
      ),
    },
    {
      name: "Email Address",
      selector: (row) => (
        <input
          type="email"
          className="form-control"
          // value={row.contact_email_address || ""}
          readOnly
        />
      ),
    },
    {
      name: "Contact Name",
      cell: (row, index) => (
        <input
          type="text"
          className="form-control"
          // value={row.contact_name}
          readOnly
        />
      ),
    },
    {
      name: "Physical Address",
      cell: (row, index) => (
        <textarea
          type="textarea"
          className="form-control"
          // value={row.physical_address}
          readOnly
        />
      ),
    },
    {
      name: "",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <img src={Eye} className="inventory" alt="inventory" />
        </button>
      ),
    },
  ];

const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick onClose={onClose}>
        <div className="editPopup" style={{ height: "600px" }}>
          <form onSubmit={handleSubmit}>
            <div className="row mt-1 popup">
              <div className="col-12 mt-1">
                <label htmlFor="tb_branchName" className="">
                  Branch Name
                </label>
                <input
                  type="text"
                  id="tb_branchName"
                  name="branchName"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.branchName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-6 mt-1">
                <label htmlFor="tb_branchCode" className="">
                  Branch Code
                </label>
                <input
                  type="text"
                  id="tb_branchCode"
                  name="branchCode"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.branchCode}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-6 mt-1">
                <label htmlFor="tb_contactNumber" className="">
                  Contact Number
                </label>
                <input
                  type="text"
                  id="tb_contactNumber"
                  name="contactNumber"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.contactNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 mt-1">
                <label htmlFor="tb_emailAddress" className="">
                  Email Address
                </label>
                <input
                  type="text"
                  id="tb_emailAddress"
                  name="emailAddress"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.emailAddress}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 mt-1">
                <label htmlFor="tb_contactName" className="">
                  Contact Name
                </label>
                <input
                  type="text"
                  id="tb_contactName"
                  name="contactName"
                  className="inputBox form-control formControl  text-dark"
                  value={branchDetails.contactName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="tb_PhysicalAddress" className="">
                  Physical Address
                </label>
                <textarea
                  type="text"
                  id="tb_PhysicalAddress"
                  name="physicalAddress"
                  className="inputBox form-control textarea bg-light text-dark tb_address"
                  
                  cols="100"
                  value={branchDetails.physicalAddress}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <button className="btnCTA btnAddSave col-6" type="submit">
              Save
            </button>
          </form>
          <DataTable
            className="resultData"
            columns={columns}
            data={formData}
            pagination
            responsive
            id="myTable"
            customStyles={tableStyles}
          />
        </div>
      </Popup>
    </div>
  );
};

export default EditBranchDetails;
