import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "./../../optoCustom.js";
import { useNavigate } from "react-router-dom";

const EditMyProfilePopup = ({ open, onClose, customHeight }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    tb_name: "",
    tb_emailAddress: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: "post",
        url: API_BASE_URL + "INVENTORY/addInventoryHandler.php",
        // data: params,
        data: [{
          tb_name: formData.tb_name,
          tb_emailAddress: formData.tb_emailAddress,
        }],
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then(function (response) {
          if (response.status == "200") {
            console.log(response);
            toast.success(response.data["success"], { position: "top-center" });
            navigate(`/Homepage/ViewPatient`);
          }
          console.log(response.status);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === "401") {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else if (error.request) {
            console.error("Network Error:", error.request);
            toast.error("Network Error", error.request, {
              position: "top-center",
            });
          } else {
            console.log("Error:", error.message);
          }
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  };

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleReset = () => {
    setFormData({
      db_supplierName: "",
      tb_netAmount: "",
      tb_orderDate: "",
      db_status: "",
    });
  };


  return (
    <div>
      <ToastContainer />
      <Popup open={open} closeOnDocumentClick onClose={onClose}>
        <div className="editPopup" style={{ height: {customHeight} }}>
          <div className="row mt-1 popup">
            <div className="col-md-12 formGroup">
              <label htmlFor="tb_name" className="">
                Name
              </label>
              <input
                type="text"
                id="tb_name"
                name="tb_name"
                className="inputBox form-control formControl text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 formGroup">
              <label htmlFor="tb_contactNumber" className="">
                Contact Number
              </label>
              <input
                type="text"
                id="tb_contactNumber"
                name="tb_contactNumber"
                className="inputBox form-control formControl text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6 formGroup">
              <label htmlFor="tb_emailAddress" className="">
                Email Address
              </label>
              <input
                type="email"
                id="tb_emailAddress"
                name="tb_emailAddress"
                className="inputBox form-control formControl text-dark"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-6 ">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={() => handleReset()}
              >
                Reset
              </button>
            </div>
            <div className="col-md-6 ">
              <button
                type="submit"
                className="btnCTA btnAddSave"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default EditMyProfilePopup;
