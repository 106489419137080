import React from 'react';
import Eye from "../../../Assets/Eye.svg";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Edit.css";
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
const EditDigitalDetails = ({ open, onClose }) => {
    const navigate = useNavigate();
    const [formData, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            axios
                .get("https://jsonplaceholder.typicode.com/users")
                .then((res) => setData(res.data))
                .catch((err) => console.log(err));
        };
        fetchData();
    }, []);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          console.log(formData.tb_clientName);
          axios({
            method: 'post',
            url: API_BASE_URL + 'GODMODE/addDigitalHandler.php',
            // data: params,
            data: {
              'tb_documentName': formData.tb_documentName,
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: true,
          }).then(function (response) {
            if (response.status == '200') {
              console.log(response)
              toast.success(response.data['success'], { position: "top-center" });
              navigate(`/Homepage/ViewClient`);
            }
            console.log(response.status)
          }).catch(function (error) {
            if (error.response) {
              if (error.response.status === '401') {
                toast.error(error.response.data['error'], { position: "top-center" });
              } else {
                toast.error(error.message, { position: "top-center" });
              }
            } else if (error.request) {
              console.error('Network Error:', error.request);
              toast.error('Network Error', error.request, { position: "top-center" });
            } else {
              console.log('Error:', error.message);
            }
          });
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
          }
      };

    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Column 2",
            selector: (row) => row.address.street,
        },
        {
            name: "Column 3",
            selector: (row) => row.phone,
        },
        {
            name: "Column 4",
            selector: (row) => row.website,
        },
        {
            name: "",
            cell: (row) => (
                <button
                    type="button"
                    className="btnEYE"
                    style={{ border: "none", background: "none" }}
                >
                    <img src={Eye} className="inventory" alt="inventory" />
                </button>
            ),
        },
    ];

    // Custom styles for DataTable
    const tableStyles = {
        headRow: {
            style: {
                backgroundColor: "#E6E6E6",
                fontWeight: 600,
                borderBottom: "2px solid #D7D8D8",
            },
        },
        headCells: {
            style: {},
        },
        rows: {
            style: {},
        },
        cells: {
            style: {},
        },
    };

    return (
        <div>
            <ToastContainer/>
            <Popup open={open} closeOnDocumentClick onClose={onClose}>
                <div className="editPopup" style={{ height: "600px" }}>
                    <div className='row mt-1 popup'>
                        <label htmlFor="tb_documentName" className="">
                            Digital Name
                        </label>
                        <input
                            className="form-control form-control-lg formControl"
                            type="file"
                            id="tb_documentName"
                            name='tb_documentName'
                            onChange={handleFileChange}
                        />
                    </div>
                    <button className='btnCTA btnAddSave col-6' onClick={handleSubmit}>
                        Save
                    </button>
                    <DataTable
                        className="resultData"
                        columns={columns}
                        data={formData}
                        pagination
                        responsive
                        id="myTable"
                        customStyles={tableStyles}
                    />
                </div>
            </Popup>
        </div>
    );
};

export default EditDigitalDetails;
