import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditPatientMainDetails = ({ open }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_patientName: '',
    tb_clientName: '',
    tb_emailAddress: '',
    tb_primaryContact: '',
    tb_patientNamesecondaryContact: '',
    tb_gender: '',
    tb_dateOfBirth: '',
    tb_physicalAddress: '',
    tb_postBox: '',
    tb_occupation: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const today = new Date().toISOString().split("T")[0];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const params = new URLSearchParams();
      console.log(formData.tb_clientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/PATIENT/editPatientMainDetailsHandler.php',
        // data: params,
        data: {
          'tb_patientName': formData.tb_patientName,
          'tb_primaryNumber': formData.tb_primaryNumber,
          'tb_secondaryNumber': formData.tb_secondaryNumber,
          'tb_gender': formData.tb_gender,
          'tb_emailAddress': formData.tb_emailAddress,
          'tb_physicalAddress': formData.tb_physicalAddress,
          'tb_poBox': formData.tb_poBox,
          'tb_dob': formData.tb_dob,
          'tb_occupation': formData.tb_occupation,
          'tb_reference': formData.tb_reference,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success(response.data['success'], { position: "top-center" });
          navigate(`/Homepage/ViewPatient`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  return (
    <div>
      <ToastContainer/>
      <Popup open={open} closeOnDocumentClick>
        <div className="editPopup" style={{height: "600px"}}>
        <div className="row ">
        <div className="col-md-12 formGroup">
        <label htmlFor="tb_patientName" className="">
          Patient Name
        </label>
        <input
          type="text"
          id="tb_patientName"
          name="tb_patientName"
          className="inputBox form-control formControl text-dark"
          onChange={handleChange}
        />
      </div>

      {/* Container for Client Name and Email Address */}
      <div className="formGroup d-flex">
        <div className="col-md-6 pe-2">
          <label htmlFor="tb_clientName" className="">
            Client Name
          </label>
          <input
            type="text"
            id="tb_clientName"
            name="tb_clientName"
            className="inputBox form-control formControl text-dark"
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 ps-2">
          <label htmlFor="tb_emailAddress" className="">
            Email Address
          </label>
          <input
            type="email"
            id="tb_emailAddress"
            name="tb_emailAddress"
            className="inputBox form-control formControl text-dark"
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Container for Primary Contact and Secondary Contact */}
      <div className="formGroup d-flex">
        <div className="col-md-6 pe-2">
          <label htmlFor="tb_primaryNumber" className="">
            Primary Contact
          </label>
          <input
            type="number"
            id="tb_primaryNumber"
            name="tb_primaryNumber"
            className="inputBox form-control formControl text-dark"
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 ps-2">
          <label htmlFor="tb_secondaryNumber" className="">
            Secondary Contact
          </label>
          <input
            type="number"
            id="tb_secondaryNumber"
            name="tb_secondaryNumber"
            className="inputBox form-control formControl text-dark"
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Container for Gender and Date of Birth */}
      <div className="formGroup d-flex">
        <div className="col-md-6 pe-2">
          <label className="form-check-label" htmlFor="gender">
            Gender
          </label>
          <div className="radioGroup d-flex">
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="radio"
                name="tb_gender"
                id="genderMale"
                value="male"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="genderMale">
                Male
              </label>
            </div>
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="radio"
                name="tb_gender"
                id="genderFemale"
                value="female"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="genderFemale">
                Female
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-6 ps-2">
          <label htmlFor="tb_dob" className="">
            Date Of Birth
          </label>
          <input
            className="form-control formControl"
            type="date"
            id="tb_dob"
            max={today}
            name="tb_dob"
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Container for Occupation and Post Box */}
      <div className="formGroup d-flex">
        <div className="col-md-6 pe-2">
          <label htmlFor="tb_postBox" className="">
            Post Box
          </label>
          <input
            type="text"
            id="tb_postBox"
            name="tb_postBox"
            className="inputBox form-control formControl text-dark"
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6 ps-2">
          <label htmlFor="tb_occupation" className="">
            Occupation
          </label>
          <input
            type="text"
            id="tb_occupation"
            name="tb_occupation"
            className="inputBox form-control formControl text-dark"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="col-md-12 formGroup">
        <label htmlFor="tb_physicalAddress" className="">
          Physical Address
        </label>
        <textarea
          id="tb_physicalAddress"
          name="tb_physicalAddress"
          className="inputBox form-control textarea text-dark tb_address"
          
          cols="100"
          onChange={handleChange}
          />
       </div>
        </div>
        <div className="row btnGroup">
          <div className="col-md-6 ">
            <button type="submit" className="btnCTA" onClick={handleSubmit}>
              Save
            </button>
          </div>
          <div className="col-md-6 ">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
        </div>
        </div>
      </Popup>
    </div>
  );
};

export default EditPatientMainDetails;
