import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import StockDetails from "../../Inventory/StockDetails.jsx";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faDownload,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

const ViewReceipts = () => {
  const [formData, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get("https://jsonplaceholder.typicode.com/users")
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://handler.optolinq.online/DIAGNOSIS/deletePrescriptionHandler.php",
        formData
      );
      console.log("Document has been Deleted successfully", response.data);
      toast.success(
        "Document has been Deleted successfully",
        response.data["success"],
        { position: "top-center" }
      );
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  };

const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };
  const columns = [
    {
      name: "Prescription Details",
      // selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Course Duration",
      // selector: (row) => row.name,
    },
    {
      name: "Follow up Date",
      // selector: (row) => row.address.street,
    },
    {
      name: "Prescription Date",
      // selector: (row) => row.phone,
    },

    {
      name: "Download",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={"/Hompage/ViewDiagnosis"}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faDownload}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
    {
      name: "Delete",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
          onClick={handleDelete}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTrashCan}
            style={{
              color: "#717171",
              width: "25px",
              height: "auto",
            }}
          />
        </button>
      ),
    },
  ];

  return (
    <div className="viewFormContainer row">
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading"> </div>
        </div>
        <Link to={"/Homepage/SearchDiagnosis"}>
          <button className="btnClose d-flex justify-content-center align-items-center mb-3">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      <form className="inputContent col-md-9">
        <div className="row">
          <div className="col-4 formGroup">
            <label htmlFor="tb_name" className="">
              Name On the Receipt
            </label>
            <input
              type="text"
              id="tb_name"
              name="tb_name"
              className="inputBox form-control  text-dark"
              value={formData.tb_name}
              readOnly
            />
          </div>
          <div className="col-md-4 formGroup">
            <label htmlFor="tb_receiptNumber" className="">
              Receipt Number
            </label>
            <input
              type="text"
              id="tb_receiptNumber"
              name="tb_receiptNumber"
              className="inputBox form-control  text-dark"
              maxLength="12"
              value={formData.tb_receiptNumber}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_quantity" className="">
              Quantity
            </label>
            <input
              type="text"
              id="tb_quantity"
              name="tb_quantity"
              className="inputBox form-control  text-dark"
              value={formData.tb_quantity}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_referenceType" className="">
              Reference Type
            </label>
            <input
              type="text"
              id="tb_referenceType"
              name="tb_referenceType"
              className="inputBox form-control  text-dark"
              value={formData.tb_referenceType}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_grossAmount" className="">
              Gross Amount
            </label>
            <input
              type="text"
              id="tb_grossAmount"
              name="tb_grossAmount"
              className="inputBox form-control  text-dark"
              value={formData.tb_currency}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_totalDiscount" className="">
              Total Discount
            </label>
            <input
              type="text"
              id="tb_totalDiscount"
              name="tb_totalDiscount"
              className="inputBox form-control  text-dark"
              value={formData.tb_totalDiscount}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_totaltax" className="">
              Total Tax
            </label>
            <input
              type="text"
              id="tb_totalTax"
              name="tb_totalTax"
              className="inputBox form-control  text-dark"
              value={formData.tb_totalTax}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_totalNetAmount" className="">
              Total Net Amount
            </label>
            <input
              type="text"
              id="tb_totalNetAmount"
              name="tb_totalNetAmount"
              className="inputBox form-control  text-dark"
              value={formData.tb_totalNetAmount}
              readOnly
            />
          </div>
          <div className="col-4 formGroup">
            <label htmlFor="tb_bankAccount" className="">
              Our bank Account
            </label>
            <input
              type="text"
              id="tb_bankAccount"
              name="tb_bankAccount"
              className="inputBox form-control  text-dark"
              value={formData.tb_bankAccount}
              readOnly
            />
          </div>
        </div>
      </form>
      <div className="linkContainer col-2 d-flex flex-column">
        <div className="formSubHeading">Quick links</div>
        <div className="mb-3">
          <StockDetails
            dropdownTitle="By Color"
            displayType="dropstart"
            buttonwidth="150px"
          />
        </div>
        <div className="mb-3">
          <StockDetails
            dropdownTitle="By Name"
            displayType="dropstart"
            buttonwidth="150px"
          />
        </div>
        <div className="mb-3">
          <StockDetails
            dropdownTitle="By Brand"
            displayType="dropstart"
            buttonwidth="150px"
          />
        </div>
        <div className="mb-3">
          <StockDetails
            dropdownTitle="By Style"
            displayType="dropstart"
            buttonwidth="150px"
          />
        </div>
      </div>
      <hr />
      {/* <div>
        <div className="formHeading">PRESCRIPTION TABLE</div>
        <DataTable
          className="resultData"
          columns={columns}
          data={formData}
          pagination
          responsive
          id="myTable"
          customStyles={tableStyles}
        ></DataTable>
      </div> */}
    </div>
  );
};

export default ViewReceipts;
