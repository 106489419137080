import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../optoCustom.js';
import { Link, useNavigate } from "react-router-dom";
const AddPatient = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_patientName: "",
    tb_clientId: "",
    tb_primaryNumber: "",
    tb_secondaryNumber: "",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_poBox: "",
    tb_gender: "",
    tb_dob: "",
    tb_occupation: "",
  });
  const [dob, setDob] = useState("");
  const [error, setError] = useState("");

  const today = new Date().toISOString().split("T")[0];

  // Handle input change
  const handleChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if(e.target.name === "tb_dob"){
      const selectedDate = e.target.value;
      if (selectedDate > today) {
        setError("Date of birth cannot be a future date.");
      } else {
        setError("");
      }
    }
  };

  //handles value change of single select and adds them to formData
  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if(singleselectedOption == null){
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else{
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };


  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getClientList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);
  
 const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    axios({
      method: 'post',
      url: API_BASE_URL + 'MASTER/PATIENT/addPatientHandler.php',
      // data: params,
      data: {
        'tb_patientName': formData.tb_patientName,
        'tb_clientId': formData.tb_clientId,
        'tb_primaryNumber': formData.tb_primaryNumber,
        'tb_secondaryNumber': formData.tb_secondaryNumber,
        'tb_gender': formData.tb_gender,
        'tb_emailAddress': formData.tb_emailAddress,
        'tb_physicalAddress': formData.tb_physicalAddress,
        'tb_poBox': formData.tb_poBox,
        'tb_dob': formData.tb_dob,
        'tb_occupation': formData.tb_occupation,
      },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true,
    }).then(function (response) {
      if (response.status == '200') {
        console.log(response)
        toast.success(response.data['success'], { position: "top-center" });
        navigate(`/Homepage/ViewClient`);
      }
      console.log(response.status)
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status === '401') {
          toast.error(error.response.data['error'], { position: "top-center" });
        } else {
          toast.error(error.message, { position: "top-center" });
        }
      } else if (error.request) {
        console.error('Network Error:', error.request);
        toast.error('Network Error', error.request, { position: "top-center" });
      } else {
        console.log('Error:', error.message);
      }
    });
  } catch (error) {
    console.error('Error:', error);
    toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
  }
};

  return (
    <>
    <ToastContainer />
      {/* Heading and Sub Heading */}

      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
        <div className="formGroup col-12">
          <label htmlFor="singleSelect">Cilent Name</label>
          <Select
            options={createdByOptions}
            key={'client_id'}
            placeholder="Select Client"
            name="tb_clientId"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleSingleSelect}
          />
        </div>
          {/* String Fields */}
          <div className="col-md-12  formGroup">
            <label htmlFor="tb_patientName" className="">
              Patient Name
            </label>
            <input
              type="text"
              id="tb_patientName"
              name="tb_patientName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_primaryNumber" className="">
              Primary Contact
            </label>
            <input
              type="number"
              id="tb_primaryNumber"
              name="tb_primaryNumber"
              className="inputBox form-control formControl  text-dark"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_secondaryNumber" className="">
              Secondary Contact
            </label>
            <input
              type="number"
              id="tb_secondaryNumber"
              name="tb_secondaryNumber"
              className="inputBox form-control formControl  text-dark"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_emailAddress" className="">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          {/* Datetimepicker */}
          <div className="formGroup col-md-3 formGroup">
            <label htmlFor="tb_dob" className="">
              Date Of Birth
            </label>
            <input
              className="form-control formControl"
              type="date"
              id="tb_dob"
              name="tb_dob"
              max={today}
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-3">
          <label className="form-check-label" htmlFor="gender">
            Gender
          </label>
          <div className="radioGroup d-flex justify-content-start">
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="radio"
                name="tb_gender"
                id="genderMale"
                value="male"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="genderMale">
                Male
              </label>
            </div>
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="radio"
                name="tb_gender"
                id="genderFemale"
                value="female"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="genderFemale">
                Female
              </label>
            </div>
          </div>
        </div>
          
          <div className="col-md-8 formGroup">
            <label htmlFor="tb_physicalAddress" className="">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
              
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="col-4  formGroup">
            <label htmlFor="tb_poBox" className="">
              Post Box
            </label>
            <input
              type="text"
              id="tb_poBox"
              name="tb_poBox"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>

          <div className="col-6  formGroup">
            <label htmlFor="tb_occupation" className="">
              Occupation
            </label>
            <input
              type="text"
              id="tb_occupation"
              name="tb_occupation"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <Link to={"/Homepage/PatientSearch"} className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnCancel"
            >
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddPatient;
