import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
const ViewMoreHistory = () => {
    const [formData, setFormData] = useState([]);
    const [dob, setDob] = useState("");
  const [error, setError] = useState("");

  const today = new Date().toISOString().split("T")[0];
    const [historyDetails, setHistoryDetails] = useState([]);
    const historyAccordionColumns = [
        {
          name: "Client Name",
        },
        {
          name: "Primary Number",
        },
        {
          name: "Refer By",
        },
      ];


      const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });const selectedDate = e.target.value;
    
        setDob(selectedDate);
    
        // Validation: Ensure the selected date is not in the future
        if (selectedDate > today) {
          setError("Date of birth cannot be a future date.");
        } else {
          setError("");
        }
    };
    
  return (
    <div>
    <div className="formHeader d-flex align-items-center justify-content-between">
      <div className="headings">
            <div className="formHeading">View More History</div>
      </div>
      <Link to={"/Homepage/ViewClient"}>
          <button className="btnClose d-flex justify-content-center align-items-center mb-3">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
    </div>
    <hr/>
    <div className="inputContent">
    <div className="row">
    <label>Company created between</label>
            <div className="formGroup col-4">
          <input onChange={handleChange}
            className="form-control formControl"
            type="date"
            id="datePicker"
            max={today}
            value={dob} 
          />
        </div>
        And
        <div className="formGroup col-4">
        <input onChange={handleChange}
            className="form-control formControl"
            type="date"
            id="datePicker"
            max={today}
            value={dob} 
          />
        </div>
    </div>
    <div className="row">
                  <DataTable
                    className="col-12"
                    columns={historyAccordionColumns}
                    data={historyDetails}
                    pagination
                    responsive
                    id="historyAccordionTable"
                  ></DataTable>
        </div>
    </div>
    </div>
  )
}

export default ViewMoreHistory
