import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { API_BASE_URL } from "../../../../src/optoCustom.js";
const SearchDiagnosis = () => {
  const [formData, setFormData] = useState([]);
  const [diagnosisData, setDiagnosisData] = useState([]);

  const [error, setError] = useState("");

  const today = new Date().toISOString().split("T")[0];
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if(e.target.name === "tb_diagnosisDate"){
      const selectedDate = e.target.value;
      if (selectedDate > today) {
        setError("Date of birth cannot be a future date.");
      } else {
        setError("");
      }
    }
    
  };
  const handleReset = () => {
    setFormData({
      tb_patientName: "",
      tb_clientName: "",
      tb_diagnosisdate: "",
      tb_doctor: "",
    });
    setDiagnosisData([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "DIAGNOSIS/filterDiagnosisHandler.php",
        data: {
          tb_patientName: formData.tb_patientName,
          tb_clientName: formData.tb_clientName,
          tb_diagnosisdate: formData.tb_diagnosisdate,
          tb_doctor: formData.tb_doctor,
          tb_gender: formData.tb_gender,
          tb_doctorDiagnosis: formData.tb_doctorDiagnosis,
          tb_healthIssue: formData.tb_healthIssue,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setDiagnosisData(response.data.OPTO[0].OPTO_MESSAGE);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else {
            toast.error("Network Error", { position: "top-center" });
          }
        });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
    
    
  };

  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          "https://handler.optolinq.online/COMMON/getDoctorList.php"
        ); // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.docotrId, // Adjust based on your API response structure
          label: option.doctorName, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.patient_name,
      sortable: true,
    },
    {
      name: "Client Name",
      selector: (row) => row.client_name,
    },
    {
      name: "Diagnosis Date",
      selector: (row) => row.diagnosis_date,
    },
    {
      name: "Doctor",
      selector: (row) => row.doctor,
    },

    {
      name: "View",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={"/Hompage/ViewDiagnosis"}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
    ,
    {
      name: "Delete",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTrashCan}
            style={{
              color: "#717171",
              width: "25px",
              height: "auto",
            }}
          />
        </button>
      ),
    },
  ];
  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if(singleselectedOption == null){
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else{
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  return (
    <div>
      <ToastContainer />
      {/* Text Input Label-above */}
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* String Fields */}
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_patientName" className="">
                Patient Name
              </label>
              <input
                type="text"
                id="tb_patientName"
                name="tb_patientName"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12  formGroup">
              <label htmlFor="tb_clientName" className="">
                Client Name
              </label>
              <input
                type="text"
                id="tb_clientName"
                name="tb_clientName"
                className="inputBox form-control formControl  text-dark"
                onChange={handleChange}
              />
            </div>
            {/* Datepicker */}
            <div className="formGroup col-4">
              <label htmlFor="datePicker" className="">
                Diagnosis Date
              </label>
              <input
                className="form-control formControl"
                type="date"
                id="datePicker"
                max={today}
                name="tb_diagnosisdate"
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-4">
              <label htmlFor="singleSelect">Doctor</label>
              <Select
                options={createdByOptions}
                placeholder="Select Doctor"
                id="singleSelect"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #B0B0B0",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "40px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                isClearable
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-md-4">
              <label className="form-check-label" htmlFor="gender">
                Gender
              </label>
              <div className="radioGroup d-flex justify-content-start">
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="genderMale"
                    value="male"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="genderMale">
                    Male
                  </label>
                </div>
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    id="genderFemale"
                    value="female"
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="genderFemale">
                    Female
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button type="reset" className="btnCTA btnReset" onClick={handleReset}>
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA btnFilter">
                Filter
              </button>
            </div>
            <div className="col-4">
              <Link to={"/Homepage/AddDiagnosis"}>
                <button type="submit" className="btnCTA btnAddSave">
                  Add New Diagnosis
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      <DataTable
        className="resultData"
        columns={columns}
        data={diagnosisData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
    </div>
  );
};

export default SearchDiagnosis;
