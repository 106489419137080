import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const AddVouchers = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    dp_voucherDate: "",
    db_clientName: "",
    db_PaidToName: "",
    db_currencyName: "",
    db_ledger: "",
    ta_voucherDescription: "",
    db_bankName: "",
    db_paymentType: "",
    tb_transactionId: "",
    db_payeeBankName: "",
    tb_bankCharges: "",
    tb_voucherAmount: "",


  });

  const [error, setError] = useState("");

  const today = new Date().toISOString().split("T")[0];
  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options
  const [doctorOptions, setDoctorOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getClientList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          "https://handler.optolinq.online/COMMON/getDoctorList.php"
        ) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setDoctorOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);
  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if(singleselectedOption == null){
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else{
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if(e.target.name === "tb_diagnosisDate"){
      const selectedDate = e.target.value;
      if (selectedDate > today) {
        setError("Date of birth cannot be a future date.");
      } else {
        setError("");
      }
    }
  };

  
  useEffect(()=>{
    console.log(formData)
  }, [formData]);

   // Handle form submission
   const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      console.log(formData.tb_patientName);
      axios({
        method: 'post',
        url: API_BASE_URL + 'MASTER/Diagnosis/addDiagnosisHandler.php',
        // data: params,
        data: {
          'dp_voucherDate': formData.dp_voucherDate,
          'db_clientName': formData.db_clientName,
          'db_PaidToName': formData.db_PaidToName,
          'db_currencyName': formData.db_currencyName,
          'db_ledger': formData.db_ledger,
          'ta_voucherDescription': formData.ta_voucherDescription,
          'db_bankName': formData.db_bankName,
          'db_paymentType': formData.db_paymentType,
          'tb_transactionId': formData.tb_transactionId,
          'db_payeeBankName': formData.db_payeeBankName,
          'tb_bankCharges': formData.tb_bankCharges,
          'tb_voucherAmount': formData.tb_voucherAmount,
        },
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true,
      }).then(function (response) {
        if (response.status == '200') {
          console.log(response)
          toast.success("Diagnosis added successfully!", { position: "top-center" });
          navigate(`/Homepage/ViewDaignosis`);
        }
        console.log(response.status)
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
          toast.error('Network Error', error.request, { position: "top-center" });
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  return (
    <>
    <ToastContainer/>
      {/* Heading and Sub Heading */}
      
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          {/* String Fields */}
          {/* Datepicker */}
         <div className="formGroup col-4">
              <label htmlFor="dp_voucherDate" className="">
                Voucher Date
              </label>
              <input
                className="form-control formControl"
                type="date"
                id="dp_voucherDate"
                name="dp_voucherDate"
                max={today}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="formGroup col-12">
          <label htmlFor="singleSelect">Cilent Name</label>
          <Select
            options={createdByOptions}
            key={'client_id'}
            placeholder="Select Client"
            name="db_clientName"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleSingleSelect}
          />
        </div>
          <div className="col-md-12  formGroup">
            <label htmlFor="db_paidToName" className="">
              Paid To Name
            </label>
            <input
              type="text"
              id="db_paidToName"
              name="db_paidToName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
        <div className="formGroup col-6">
          <label htmlFor="singleSelect">Currency</label>
          <Select
            options={doctorOptions}
            placeholder="Select Currency"
            name="db_currencyName"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleChange}
          />
        </div>
        <div className="formGroup col-6">
          <label htmlFor="singleSelect">Ledger Name</label>
          <Select
            // options={doctorOptions}
            placeholder="Select Ledger"
            name="db_ledger"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleChange}
          />
        </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="ta_voucherDescription" className="">
              Voucher Description
            </label>
            <textarea
              type="text"
              id="ta_voucherDescription"
              name="ta_voucherDescription"
              className="inputBox form-control textarea  text-dark tb_address"
              
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-6">
          <label htmlFor="singleSelect">Bank Name</label>
          <Select
            options={doctorOptions}
            placeholder="Select Bank Name"
            name="db_BankName"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleChange}
          />
        </div>
        <div className="formGroup col-6">
          <label htmlFor="singleSelect">Payment Type</label>
          <Select
            options={doctorOptions}
            placeholder="Select Payment Type"
            name="db_paymentType"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleChange}
          />
        </div>
            <div className="formGroup col-md-6">
                  <label htmlFor="tb_transactionId" className="">
                    Transaction Id
                  </label>
                  <input
                    type="text"
                    id="tb_transactionId"
                    name="tb_transactionId"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-6">
          <label htmlFor="singleSelect">Payee Bank Name</label>
          <Select
            options={doctorOptions}
            placeholder="Select Payee Bank"
            name="db_payeeBankName"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleChange}
          />
        </div>
            <div className="formGroup col-md-6">
                  <label htmlFor="tb_bankCharges" className="">
                    Bank Charges
                  </label>
                  <input
                    type="text"
                    id="tb_bankCharges"
                    name="tb_bankCharges"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
            <div className="formGroup col-md-6">
                  <label htmlFor="db_voucherAmount" className="">
                    Voucher Amount
                  </label>
                  <input
                    type="text"
                    id="db_voucherAmount"
                    name="db_voucherAmount"
                    className="inputBox form-control formControl  text-dark"
                    onChange={handleChange}
                  />
            </div>
        </div>
        <div className="row btnGroup">
          <Link to={"/Homepage/SearchVouchers"} className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnCancel"
            >
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddVouchers;
