import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import './ForgotCredientials.css';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import img_optolinqName from '../../../Assets/OPTO LINQ.png';
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Card,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
  FloatingLabel,
} from "react-bootstrap";



const ForgotCredientials = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  
  const labelstyle = {
    color: "#e0e0e0",
    fontSize: "14px",
    opacity: "50%"
  };

  const linkStyle = {
    textDecoration: "none"
  };

  const inputStyle = {
    backgroundColor: "#333333",
    borderRadius: "30px",
    color: "#ffffff",
    maxWidth:" 350px",
    margin: "auto",
    border: "none",
    height: "50px"
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (username.length === 0) {
      toast.error("Username are required", {
        position: "top-center",
      });
      return;
    }

    try {
      const params = new URLSearchParams();
      params.append('tb_userNameField', username);
      params.append('opto_domain', window.location.hostname);
      axios({
        method: 'post',
        url: API_BASE_URL + 'IAMS/forgotCredendialsHandler.php',
        data: params,
        withCredentials: true,
      }).then(function (response) {
        console.log(response.data);
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status === '200') {
            toast.success(error.response.data['success'], { position: "top-center" });
            navigate(`/acknowledgement`)
          } else if (error.response.status === '401') {
            toast.error(error.response.data['error'], { position: "top-center" });
          } else {
            toast.error(error.message, { position: "top-center" });
          }
        } else if (error.request) {
          console.error('Network Error:', error.request);
        } else {
          console.log('Error:', error.message);
        }
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };
  return (
    <div>
      <ToastContainer />
      <Container className="cont_login d-flex justify-content-center align-items-center min-vh-100">
        <Card className="p-4" style={{ maxWidth: "400px", width: "100%" }}>
          <Card.Body>
            <br />
            <div className="img_optolinqName text-center mb-3">
              <img
                src={img_optolinqName}
                alt="logo"
                style={{ width: "95%", height: "auto" }}
              />
            </div>
            <Form onSubmit={handleSubmit} className="frm_login mt-3">
              <div className="text-center mt-4 name mx-auto" id="sub-heading">
                Enter your username and we'll send you a recovery email
              </div>
              <FormGroup className="tb_userNameField d-flex flex-column align-items-left mb-3 mx-auto" style={{maxWidth: "350px"}}>
                <FloatingLabel htmlFor="username"  className="username" style={labelstyle}>
                  Re-Enter Username
                </FloatingLabel>
                <InputGroup>
                <FormControl
                    type="text"
                    id="tb_userNameField"
                    name="tb_userNameField"
                    className="tb_userNameFieldInput"
                    placeholder="Username"
                    value={username}
                    onChange={handleUsernameChange}
                    style={inputStyle}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button type="submit" as={Link} to={"/acknowledgement"} style={linkStyle} onClick={handleSubmit} id='btn_getEmail' name='btn_getEmail' className="btn_login mt-3 w-100 d-flex justify-content-center align-items-center mx-auto">
                  Get Email
                </Button>
              </div>
              <br />
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default ForgotCredientials
