import React from "react";
import img_optolinqName from "../../Assets/OPTO LINQ.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Card, Form} from "react-bootstrap";

export default function AccountBlocked() {
  return (
    <div>
      <Container className="cont_login d-flex justify-content-center align-items-center min-vh-100">
        <Card className="p-4" style={{ maxWidth: "400px", width: "100%" }}>
          <Card.Body>
            <br />
            <div className="img_optolinqName text-center mb-3">
              <img
                src={img_optolinqName}
                alt="logo"
                style={{ width: "95%", height: "auto" }}
              />
            </div>
            <Form className="frm_login mt-3">
              <div className="text-center mt-4 name" id="sub-heading">
                Your Account has be blocked, Please kindly contact your administrator.
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
