import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import { API_BASE_URL } from '../../../../src/optoCustom.js';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const AddCompany = () => {
  // State to handle form data
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_companyName: "",
    tb_companyShortName: "",
    tb_companySmallName: "",
    tb_companyTIN: "",
    tb_companyVRN: "",
    tb_companyURL: "",
    tb_companyBranchName: "",
    tb_companyBranchCode:"",
    tb_companyContactName: "",
    tb_companyEmailAddress: "",
    tb_companyContactNumber: "",
    tb_companyPhysicalAddress: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  //testing formData change handling
  useEffect(() => {
    console.log(formData)
  }, [formData])

  

 // Handle form submission
 const handleSubmit = async (e) => {
  e.preventDefault();

  try {

    console.log(formData.tb_clientName);
    axios({
      method: 'post',
      url: API_BASE_URL + 'GODMODE/companyAddHandler.php',
      // data: params,
      data: {
        'tb_companyName': formData.tb_companyName,
        'tb_companyShortName': formData.tb_companyShortName,
        'tb_companySmallName': formData.tb_companySmallName,
        'tb_companyTIN': formData.tb_companyTIN,
        'tb_companyVRN': formData.tb_companyVRN,
        'tb_companyURL': formData.tb_companyURL,
        'tb_companyBranchName': formData.tb_companyBranchName,
        'tb_companyBranchCode': formData.tb_companyBranchCode,
        'tb_companyEmailAddress': formData.tb_companyEmailAddress,
        'tb_companyContactNumber': formData.tb_companyContactNumber,
        'tb_companyContactName': formData.tb_companyContactName,
        'tb_companyPhysicalAddress': formData.tb_companyPhysicalAddress,
      },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true,
    }).then(function (response) {
      if (response.status == '200') {
        console.log(response)
        toast.success(response.data['success'], { position: "top-center" });
        navigate(`/Godpage/View`);
      }
      console.log(response.status)
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status === '401') {
          toast.error(error.response.data['error'], { position: "top-center" });
        } else {
          toast.error(error.message, { position: "top-center" });
        }
      } else if (error.request) {
        console.error('Network Error:', error.request);
        toast.error('Network Error', error.request, { position: "top-center" });
      } else {
        console.log('Error:', error.message);
      }
    });
  } catch (error) {
    console.error('Error:', error);
    toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
  }
};

  return (
    <>
    <ToastContainer/>
      {/* Heading and Sub Heading */}
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading">ADD COMPANY</div>
          <div className="formSubHeading">
            Fill up the details below to add a new Company
          </div>
        </div>
        <Link to={"/Godpage/Company"}>
          <button className="btnClose d-flex justify-content-center align-items-center mb-3">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row">
          {/* String Fields */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_companyName" className="">
              Company Name{" "}
            </label>
            <input
              type="text"
              id="tb_companyName"
              name="tb_companyName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-3 formGroup">
            <label htmlFor="tb_companyShortName" className="">
              Short Name
            </label>
            <input
              type="text"
              id="tb_companyShortName"
              name="tb_companyShortName"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-5 formGroup">
            <label htmlFor="tb_companySmallName" className="">
              Small Name
            </label>
            <input
              type="text"
              id="tb_companySmallName"
              name="tb_companySmallName"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          {/* Pincode Fields */}
          <div className="col-md-2 formGroup">
            <label htmlFor="tb_companyTIN" className="">
              TIN
            </label>
            <input
              type="text"
              id="tb_companyTIN"
              name="tb_companyTIN"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-2 formGroup">
            <label htmlFor="tb_companyVRN" className="">
              VRN
            </label>
            <input
              type="text"
              id="tb_companyVRN"
              name="tb_companyVRN"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 formGroup">
            <label htmlFor="tb_companyURL" className="">
              Company URL
            </label>
            <input
              type="text"
              id="tb_companyURL"
              name="tb_companyURL"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 formGroup">
            <label htmlFor="tb_companyBranchCode" className="">
              Branch Code
            </label>
            <input
              type="text"
              id="tb_companyBranchCode"
              name="tb_companyBranchCode"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 formGroup">
            <label htmlFor="tb_companyBranchName" className="">
              Branch Name
            </label>
            <input
              type="text"
              id="tb_companyBranchName"
              name="tb_companyBranchName"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 formGroup">
            <label htmlFor="tb_companyContactName" className="">
              Contact Name
            </label>
            <input
              type="text"
              id="tb_companyContactName"
              name="tb_companyContactName"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          {/* Email Field */}
          <div className="col-md-6 formGroup">
            <label htmlFor="tb_companyEmailAddress" className="">
              Primary Email Address
            </label>
            <input
              type="email"
              id="tb_companyEmailAddress"
              name="tb_companyEmailAddress"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 formGroup">
            <label htmlFor="tb_companyContactNumber" className="">
              Primary Contact Number
            </label>
            <input
              type="text"
              id="tb_companyContactNumber"
              name="tb_companyContactNumber"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-3 formGroup">
          {/* Contact Number Field */}
          <div className="col-md-12">
            <label htmlFor="tb_companyPhysicalAddress" className="">
              Provide Portion of Physical Address
            </label>
            <input
              type="text"
              id="tb_companyPhysicalAddress"
              name="tb_companyPhysicalAddress"
              className="inputBox form-control formControl  text-dark tb_address"
              
              cols="100"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
        <div className="col-md-6 formGroup">
          <button type="submit" className="btnCTA btnAddSave">
            Save
          </button>
        </div>
        <div className="col-md-6 formGroup">
          <button
            type="reset"
            className="btnCTA btnReset"
            // onClick={() => window.location.reload()}
          >
            Reset
          </button>
        </div>
      </div>
      </form>

      
    </>
  );
};

export default AddCompany;
