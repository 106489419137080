import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
const AddReceipts = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    tb_name: "",
    tb_receiptNumber: "",
    tb_referenceType: "",
    tb_quantity: "",
    tb_grossAmount:"",
    tb_totalDiscount:"",
    tb_totalNetAmount:"",
    tb_totalTax:"",
  });

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };
  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getClientList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const options_xxxx = [
    {
      value: "Dignosis",
      label: "Dignosis",
    },
    {
      value: "Receipts",
      label: "Receipts",
    },
  ];

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/CLIENT/addClientHandler.php",
        // data: params,
        data: {
            tb_name: formData.tb_name,
            tb_receiptNumber: formData.tb_receiptNumber,
            tb_referenceType: formData.tb_referenceType,
            tb_grossAmount: formData.tb_grossAmount,
            tb_quantity: formData.tb_quantity,
            tb_totalDiscount: formData.tb_totalDiscount,
            tb_totalNetAmount: formData.tb_totalNetAmount,
            tb_totalTax: formData.tb_totalTax,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then(function (response) {
          console.log(response);
          if (response.status == "200") {
            console.log(response);
            toast.success(response.data["success"], { position: "top-center" });
            navigate(`/Homepage/ViewClient/${response.data.OPTO[0].OPTO_ID}`);
          }
          console.log(response.status);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === "401") {
              const errorMessage = error.response.data.OPTO[0].OPTO_MESSAGE;
              toast.error(errorMessage, { position: "top-center" });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else if (error.request) {
            console.error("Network Error:", error.request);
            toast.error("Network Error", error.request, {
              position: "top-center",
            });
          } else {
            console.log("Error:", error.message);
          }
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  };

  return (
    <>
      <ToastContainer />
      {/* Heading and Sub Heading */}
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
      <div className="row">
      <div className="col-4 formGroup">
              <label htmlFor="tb_name" className="">
                Name On the Receipt
              </label>
              <input
                type="text"
                id="tb_name"
                name="tb_name"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_name}
                onChange={handleChange}
              />
            </div>
          <div className="col-md-4 formGroup">
              <label htmlFor="tb_receiptNumber" className="">
                Receipt Number
              </label>
              <input
                type="text"
                id="tb_receiptNumber"
                name="tb_receiptNumber"
                className="inputBox form-control formControl text-dark"
                maxLength="12"
                value={formData.tb_receiptNumber}
                onChange={handleChange}
              />
            </div>
            <div className="col-4 formGroup">
              <label htmlFor="tb_quantity" className="">
                Quantity
              </label>
              <input
                type="text"
                id="tb_quantity"
                name="tb_quantity"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_quantity}
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-4">
          <label htmlFor="singleSelect">Reference Type</label>
          <Select
            options={options_xxxx}
            key={'client_id'}
            placeholder="Reference Type"
            name="tb_referenceType"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            onChange={handleSingleSelect}
          />
        </div>
            <div className="col-4 formGroup">
              <label htmlFor="tb_grossAmount" className="">
                Gross Amount
              </label>
              <input
                type="text"
                id="tb_grossAmount"
                name="tb_grossAmount"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_currency}
                onChange={handleChange}
              />
            </div>
            <div className="col-4 formGroup">
              <label htmlFor="tb_totalDiscount" className="">
               Total Discount
              </label>
              <input
                type="text"
                id="tb_totalDiscount"
                name="tb_totalDiscount"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_totalDiscount}
                onChange={handleChange}
              />
            </div>
            <div className="col-4 formGroup">
              <label htmlFor="tb_totaltax" className="">
               Total Tax
              </label>
              <input
                type="text"
                id="tb_totalTax"
                name="tb_totalTax"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_totalTax}
                onChange={handleChange}
              />
            </div>
            <div className="col-4 formGroup">
              <label htmlFor="tb_totalNetAmount" className="">
               Total Net Amount
              </label>
              <input
                type="text"
                id="tb_totalNetAmount"
                name="tb_totalNetAmount"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_totalNetAmount}
                onChange={handleChange}
              />
            </div>
            <div className="col-4 formGroup">
              <label htmlFor="tb_bankAccount" className="">
               Our bank Account
              </label>
              <input
                type="text"
                id="tb_bankAccount"
                name="tb_bankAccount"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_bankAccount}
                onChange={handleChange}
              />
            </div>
          </div>
        <div className="row btnGroup">
          <Link to={"/Homepage/SearchReceipts"} className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnCancel"
            >
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddReceipts;
