import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faDownload } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from '../../../../optoCustom.js';
import { useNavigate } from "react-router-dom";
const EditClientKnowYourClientsDetails = ({ open, onClose }) => {
    const navigate = useNavigate();
    const [formData, setData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            axios
                .get("https://handler.optolinq.online/MASTER/CLIENT/addClientDocumentHandler.php")
                .then((res) => setData(res.data))
                .catch((err) => console.log(err));
        };
        fetchData();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          console.log(formData.tb_clientName);
          axios({
            method: 'post',
            url: API_BASE_URL + 'MASTER/CLIENT/addClientDocumentHandler.php',
            // data: params,
            data: {
              'tb_documentName': formData.tb_documentName,
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: true,
          }).then(function (response) {
            if (response.status == '200') {
              console.log(response)
              toast.success(response.data['success'], { position: "top-center" });
              navigate(`/Homepage/ViewClient`);
            }
            console.log(response.status)
          })
        } catch (error) {
          console.error('Error:', error);
          toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
        }
      };

    const handleDelete = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post(
            "https://handler.optolinq.online/MASTER/PATIENT/deletePatientDocumentHandler.php",
            formData
          );
          console.log("Document has been Deleted successfully", response.data);
          toast.success("Document has been Deleted successfully",response.data['success'], { position: "top-center" });
        } catch (error) {
          console.error('Error:', error);
          toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
        }
      };

    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Column 2",
            selector: (row) => row.address.street,
        },
        {
            name: "Column 3",
            selector: (row) => row.phone,
        },
        {
            name: "Download",
            cell: (row) => (
              <button
                type="button"
                className="btnEYE"
                style={{ border: "none", background: "none" }}
              >
                  <FontAwesomeIcon
                    className="actionIcon"
                    icon={faDownload}
                    style={{
                      color: "#717171",
                      width: "25px",
                      height: "auto",
                    }}
                  />
              </button>
            ),
          },{
            name: "Delete",
            cell: (row) => (
              <button
                type="button"
                className="btnEYE"
                style={{ border: "none", background: "none" }}
                onClick={handleDelete}
              >
                  <FontAwesomeIcon
                    className="actionIcon"
                    icon={faTrashCan}
                    style={{
                      color: "#717171",
                      width: "25px",
                      height: "auto",
                    }}
                  />
              </button>
            ),
          },
       
    ];

    // custom styles for result react-data-table-component
    const tableStyles = {
        headRow: {
            style: {
                backgroundColor: "#E6E6E6",
                fontWeight: 600,
                borderBottom: "2px solid #D7D8D8",
            },
        },
        headCells: {
            style: {},
        },
        rows: {
            style: {},
        },
        cells: {
            style: {},
        },
    };

    return (
        <div>
            <ToastContainer/>
            <Popup open={open} closeOnDocumentClick onClose={onClose}>
                <div className="editPopup" style={{height: "600px"}}>
                    <div className='row mt-1 popup'onClick={handleSubmit} >
                        <label htmlFor="tb_documentName" className="">
                            Document Name
                        </label>
                        <input
                            className="form-control form-control-lg formControl"
                            type="text"
                            id="tb_documentName"
                            name="tb_documentName"
                        />
                    </div>
                    <button type='submit' className='btnCTA btnAddSave col-6' onClick={handleSubmit}>
                        Save
                    </button>
                    <DataTable
                        className="resultData"
                        columns={columns}
                        data={formData}
                        pagination
                        responsive
                        id="myTable"
                        customStyles={tableStyles}
                    />
                </div>
            </Popup>
        </div>
    );
};

export default EditClientKnowYourClientsDetails;
