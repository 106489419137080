import React, { useState } from "react";
import "./SetPassword.css";
import img_optolinqName from "../../../Assets/OPTO LINQ.png";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Card,
  Form,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
  FloatingLabel,
} from "react-bootstrap";

const SetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setNewPasswordError(""); 
    setErrorMessage("");
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError("");
    setErrorMessage("");
  };

  const labelstyle = {
    color: "#e0e0e0",
    fontSize: "14px",
    opacity: "50%"
  };

  const errorStyle = {
    fontSize: "12px",
    margin: "0px",
    padding: "0px",
  };

  const inputStyle = {
    backgroundColor: "#333333",
    borderRadius: "30px",
    color: "#ffffff",
    maxWidth:" 350px",
    margin: "auto",
    border: "none",
    height: "50px"
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    let newPasswordIsValid = true;
    let confirmPasswordIsValid = true;

    if (newPassword.length === 0 && confirmPassword.length === 0) {
      setNewPasswordError("New password is required");
      setConfirmPasswordError("Confirm password is required");
      newPasswordIsValid = false;
      confirmPasswordIsValid = false;
    } else {
      setNewPasswordError("");
      setConfirmPasswordError("");

      if (newPassword.length === 0) {
        setNewPasswordError("New password is required");
        newPasswordIsValid = false;
      }

      if (confirmPassword.length === 0) {
        setConfirmPasswordError("Confirm password is required");
        confirmPasswordIsValid = false;
      }

      if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords do not match");
        newPasswordIsValid = false;
        confirmPasswordIsValid = false;
      } else {
        setErrorMessage("");
      }
    }

    if (newPasswordIsValid && confirmPasswordIsValid) {
      console.log("Password successfully changed!");
    }
  };

  return (
    <div>
      <Container className="cont_login d-flex justify-content-center align-items-center min-vh-100">
        <Card className="p-4" style={{ maxWidth: "400px", width: "100%" }}>
          <Card.Body>
            <br />
            <div className="img_optolinqName text-center mb-3">
              <img
                src={img_optolinqName}
                alt="logo"
                style={{ width: "95%", height: "auto" }}
              />
            </div>
            <Form onSubmit={handleSubmit} className="frm_login mt-3">
              <div className="text-center mt-4 name mx-auto" id="sub-heading">
                Enter and confirm your new password
              </div>

              <FormGroup className="tb_newPasswordField d-flex flex-column align-items-left mb-3 mx-auto" style={{maxWidth: "350px"}}>
                <FloatingLabel htmlFor="newPassword" className="password" style={labelstyle}>
                  New Password
                </FloatingLabel>
                <InputGroup>
                  <FormControl
                    type="password"
                    id="newPasswordInput"
                    name="tb_newPasswordFieldInput"
                    className="tb_newPasswordFieldInput"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    isInvalid={!!newPasswordError} 
                    style={inputStyle}
                  />
                  <FormControl.Feedback type="invalid" style={errorStyle}>
                    {newPasswordError}
                  </FormControl.Feedback>
                </InputGroup>
              </FormGroup>

              <FormGroup className="tb_confirmPasswordField d-flex flex-column align-items-left mb-3 mx-auto" style={{maxWidth: "350px"}}>
                <FloatingLabel htmlFor="confirmPassword" className="password" style={labelstyle}>
                  Confirm Password
                </FloatingLabel>
                <InputGroup>
                  <FormControl
                    type="password"
                    id="confirmPasswordInput"
                    name="tb_confirmPasswordFieldINput"
                    className="tb_confirmPasswordFieldInput"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    isInvalid={!!confirmPasswordError} 
                    style={inputStyle}
                  />
                  <FormControl.Feedback type="invalid" style={errorStyle}>
                    {confirmPasswordError}
                  </FormControl.Feedback>
                </InputGroup>
              </FormGroup>
              <div className="mx-auto" style={{ maxWidth: "350px" }}>
                {errorMessage && (
                  <div style={errorStyle} className="error_message">{errorMessage}</div>
                )}
              </div>
              <div className="d-flex">
                <Button
                  type="submit"
                  id="btn_changePassword"
                  name="btn_changePassword"
                  className="btn_changePassword mt-3 w-100 mx-auto"
                >
                  Change Password
                </Button>
              </div>
              <br />
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default SetPassword;
