import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../../../optoCustom.js";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const SearchReceipts = () => {
  const [formData, setFormData] = useState({
    tb_clientName: "",
    tb_receiptNumber: "",
    tb_referenceType: "",
    tb_currency: "",
    tb_modeOfPayment: "",
    tb_paymentDate: "",
    tb_transactionId: "",
    tb_bankAccount: "",
  });

  const [clientData, setClientData] = useState([]);
  useEffect(()=>{
    console.log(formData)
  },[formData])
  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  };

  //handles value change of single select and adds them to formData
  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };

  const options_xxxx = [
    {
      value: "Dignosis",
      label: "Dignosis",
    },
    {
      value: "Receipts",
      label: "Receipts",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/CLIENT/filterClientHandler.php",
        data: {
          tb_clientName: formData.tb_clientName,
          tb_receiptNumber: formData.tb_receiptNumber,
          tb_referenceType: formData.tb_referenceType,
          tb_currency: formData.tb_currency,
          tb_modeOfPayment: formData.tb_modeOfPayment,
          tb_paymentDate: formData.tb_paymentDate,
          tb_transactionId: formData.tb_transactionId,
          tb_bankAccount: formData.tb_bankAccount,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then((response) => {
          if (
            response.status === 200 &&
            response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
          ) {
            // Extract the OPTO_MESSAGE array and set it to the state
            setClientData(response.data.OPTO[0].OPTO_MESSAGE);
            // toast.success("Clients retrieved successfully!", { position: "top-center" });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else {
            toast.error("Network Error", { position: "top-center" });
          }
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  };

  const [createdByOptions, setCreatedByOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios({
          method: 'get',
          url:API_BASE_URL + '/COMMON/getClientList.php',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
      }) // Replace with your API endpoint
        const options = response.data.OPTO[0].OPTO_MESSAGE.map((option) => ({
          value: option.client_id, // Adjust based on your API response structure
          label: option.client_name, // Adjust based on your API response structure
        }));
        setCreatedByOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const handleReset = () => {
    setFormData({
      tb_clientName: "",
      tb_receiptNumber: "",
      tb_referenceType: "",
      tb_currency: "",
      tb_modeOfPayment: "",
      tb_paymentDate: "",
      tb_transactionId: "",
    });
    setClientData([]);
  };

  const columns = [
    {
      name: "Client Name",
      selector: (row) => row.receipt_Id,
      sortable: true,
    },
    {
      name: "Primary Contact Number",
      selector: (row) => row.client_name,
    },
    {
      name: "Secondary Contact Number",
      selector: (row) => row.total_amount,
    },
    {
      name: "Email Address",
      selector: (row) => row.payment_date,
    },
    {
      name: "Physical Address",
      selector: (row) => row.reference_by,
    },
    {
      name: "View",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <Link to={`/HomePage/ViewReceipts/${row.client_id}`}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={faEye}
              style={{
                color: "#717171",
                width: "25px",
                height: "auto",
              }}
            />
          </Link>
        </button>
      ),
    },
  ];

  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#E6E6E6",
        fontWeight: 600,
        borderBottom: "2px solid #D7D8D8",
      },
    },
  };

  return (
    <div>
      <ToastContainer />
      <div className="inputContent">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3 formGroup">
              <label htmlFor="tb_receiptNumber" className="">
                Receipt Number
              </label>
              <input
                type="text"
                id="tb_receiptNumber"
                name="tb_receiptNumber"
                className="inputBox form-control formControl text-dark"
                maxLength="12"
                value={formData.tb_receiptNumber}
                onChange={handleChange}
              />
            </div>
            <div className="formGroup col-3">
              <label htmlFor="singleSelect">Reference Type</label>
              <Select
                options={options_xxxx}
                key={"client_id"}
                placeholder="Reference Type"
                name="tb_referenceType"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #B0B0B0",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                isClearable
                onChange={handleSingleSelect}
              />
            </div>
            <div className="formGroup col-6">
              <label htmlFor="singleSelect">Cilent Name</label>
              <Select
                options={createdByOptions}
                key={"client_id"}
                placeholder="Select Client"
                name="tb_clientId"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "1px solid #B0B0B0",
                    borderRadius: "0px",
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0px",
                    fontSize: "14px",
                    maxHeight: "45px",
                  }),
                }}
                className=""
                isSearchable
                // isDisabled
                isClearable
                onChange={handleSingleSelect}
              />
            </div>
            <div className="col-3 formGroup">
              <label htmlFor="tb_currency" className="">
                Currency
              </label>
              <input
                type="text"
                id="tb_currency"
                name="tb_currency"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_currency}
                onChange={handleChange}
              />
            </div>
            <div className="col-3 formGroup">
              <label htmlFor="tb_modeOfPayment" className="">
                Mode Of Payment
              </label>
              <input
                type="text"
                id="tb_modeOfPayment"
                name="tb_modeOfPayment"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_modeOfPayment}
                onChange={handleChange}
              />
            </div>
            <div className="col-3 formGroup">
              <label htmlFor="tb_transactionId" className="">
                Transaction Id
              </label>
              <input
                type="text"
                id="tb_transactionId"
                name="tb_transactionId"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_transactionId}
                onChange={handleChange}
              />
            </div>
            <div className="col-3 formGroup">
              <label htmlFor="tb_bankAccount" className="">
                Our bank Account
              </label>
              <input
                type="text"
                id="tb_bankAccount"
                name="tb_bankAccount"
                className="inputBox form-control formControl text-dark"
                value={formData.tb_bankAccount}
                onChange={handleChange}
              />
            </div>
            <div className="row col-12 justify-content-center align-content-center">
              <label className="col-12 text-center">Receipt Payment Date</label>
              <div className="formGroup col-4 justify-content-center align-content-center">
                <input
                  onChange={handleChange}
                  className="form-control formControl"
                  type="date"
                  id="tb_paymentDate"
                  name="tb_paymentDate"
                  max={today}
                />
              </div>
              And
              <div className="formGroup col-4 justify-content-center align-content-center">
                <input
                  onChange={handleChange}
                  className="form-control formControl"
                  type="date"
                  id="tb_paymentDate"
                  name="tb_paymentDate"
                  max={today}
                />
              </div>
            </div>
          </div>
          <div className="row btnGroup">
            <div className="col-md-4">
              <button
                type="reset"
                className="btnCTA btnReset"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="col-md-4">
              <button type="submit" className="btnCTA  btnFilter">
                Filter
              </button>
            </div>
            <div className="col-md-4">
              <Link to={"/Homepage/AddReceipts"}>
                <button type="button" className="btnCTA btnAddSave">
                  Add Receipts
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>

      {/* DataTable to display client data */}
      <DataTable
        className="resultData"
        columns={columns}
        data={clientData} // Data received from API
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      />
    </div>
  );
};

export default SearchReceipts;
