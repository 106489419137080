import React from "react";
import "./HomePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import DataTable from "react-data-table-component";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "react-calendar/dist/Calendar.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";

import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const Formtemplate = () => {
  const options_xxxx = [
    {
      value: "somevalue1",
      label: "Some Label",
    },
    {
      value: "somevalue2",
      label: "Some Label",
    },
    {
      value: "somevalue3",
      label: "Some Label",
    },
    {
      value: "somevalue4",
      label: "Some Label",
    },
  ];

  // columns and selectors for results table
  const columns = [
    {
      name: "ID",
      maxWidth: "fit-content",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Column 2",
      selector: (row) => row.address.street,
    },
    {
      name: "Column 3",
      selector: (row) => row.phone,
    },
    {
      name: "Column 4",
      selector: (row) => row.website,
    },
    {
      name: "",
      maxWidth: "fit-content",
      cell: (row) => (
        <button
          type="button"
          className="btnEYE"
          style={{ border: "none", background: "none" }}
        >
          <FontAwesomeIcon
            icon={faEye}
            style={{ color: "#717171", height: "25px" }}
          />
        </button>
      ),
    },
    {
      name: "",
      maxWidth: "fit-content",
      cell: (row) => (
        <button
          type="button"
          className="btnDelete"
          style={{ border: "none", background: "none" }}
        >
          <FontAwesomeIcon
            icon={faTrashCan}
            style={{ color: "#717171", height: "25px" }}
          />
        </button>
      ),
    },
  ];

  const [formData, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      axios
        .get("https://jsonplaceholder.typicode.com/users")
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  // custom styles for result react-data-table-component
const tableStyles = {
    headRow: {
      style: {
        backgroundColor: "#EBF0F4",
        fontWeight: 600,
        borderBottom: "3px solid #CDE6E6",
        fontSize: "14px",
        color: "#5d5d5d"
      },
    },
    headCells: {
      style: {},
    },
    rows: {
      style: {},
    },
    cells: {
      style: {},
    },
  };

  // change handlers and states

  // Single select
  const [singleselectedOption, setsingleSelectedOption] = useState("");

  const handleSingleSelect = (singleselectedOption) => {
    setsingleSelectedOption(singleselectedOption);
    console.log(singleselectedOption);
  };

  // Multiple select
  const [multipleselectedOption, setmultipleSelectedOption] = useState("");

  const handleMultipleSelect = (multipleselectedOption) => {
    setmultipleSelectedOption(multipleselectedOption.value);
    console.log(
      multipleselectedOption.map((option) => console.log(option.value))
    );
  };

  //Datepicker
  const [datepickerVal, setdatepickerVal] = useState("");

  const handleDatePicker = (event) => {
    setdatepickerVal(event.target.value);
    console.log(datepickerVal);
  };

  //Timepicker
  const [timepickerVal, setTimepickerVal] = useState("");

  const handleTimePicker = (event) => {
    setTimepickerVal(event.target.value);
  };

  // Datetimepicker
  const [datetimeVal, setDatetimeVal] = useState("");

  const handleDatetimePicker = (event) => {
    setDatetimeVal(event.target.value);
    console.log(datetimeVal);
  };

  //DateRangePicke
  const [dateRange, setdateRange] = useState([new Date(), new Date()]);
  const handleDateRangeChange = (range) => {
    setdateRange(range);
  };

  // TimeRangePicker
  const [timeRange, setTimeRange] = useState(["10:00", "11:00"]);

  // DateTimeRange picker
  const [dateTimeRange, setDateTimeRange] = useState(new Date());
  const handleDateTimeRangeChange = (range) => {
    setDateTimeRange(range);
  };

  return (
    <>
      {/* Form Card and its results */}
      {/* Heading and Sub Heading */}
      <div className="formHeading">FORM TEMPLATE</div>
      <div className="formSubHeading">
        This is a form template. Each component is well defined using comments,
        styles are used from Homepage.css file and change handlers along with
        states are also included in this file
      </div>
      {/* inputs */}

      {/* Text Input Label-above */}
      <div className="row  inputContent d-flex flex-wrap flex-row ">
        <div className="form-group col-4 formGroup">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control formControl"
            id="exampleInputEmail1"
            placeholder="Enter email"
          />
        </div>
        <div className="form-group col-4 formGroup">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="password"
            className="form-control formControl"
            id="exampleInputEmail1"
            placeholder="Enter email"
          />
        </div>
        <div className="form-group col-4 formGroup">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="password"
            className="form-control formControl"
            id="exampleInputEmail1"
            placeholder="Enter email"
          />
        </div>
        {/* FileInput */}

        {/* Text Input Label-aside */}
        <div className="label-aside d-flex flex-row align-items-center formGroup col-4">
          <div className="col-2">
            <label htmlFor="inputPassword6" className="col-">
              Label Aside
            </label>
          </div>
          <div className="col-10">
            <input
              type="password"
              id="inputPassword6"
              className="form-control formControl"
              aria-describedby="passwordHelpInline"
            />
          </div>
        </div>
        <div className="label-aside d-flex flex-row align-items-center formGroup col-4">
          <div className="col-2">
            <label htmlFor="inputPassword6" className="col-">
              Password
            </label>
          </div>
          <div className="col-10">
            <input
              type="password"
              id="inputPassword6"
              className="form-control formControl"
              aria-describedby="passwordHelpInline"
            />
          </div>
        </div>
        <div className="label-aside d-flex flex-row align-items-center formGroup col-4">
          <div className="col-2">
            <label htmlFor="inputPassword6" className="col-">
              Password
            </label>
          </div>
          <div className="col-10">
            <input
              type="password"
              id="inputPassword6"
              className="form-control formControl"
              aria-describedby="passwordHelpInline"
            />
          </div>
        </div>

        {/* Textarea */}
        <div className="formGroup">
          <label htmlFor="formFile" className="">
            Default file input example
          </label>
          <input
            className="form-control form-control-lg formControl"
            type="file"
            id="formFile"
          />
        </div>

        {/* Datepicker */}
        <div className="formGroup col-2">
          <label htmlFor="datePicker" className="">
            Default date input example
          </label>
          <input
            className="form-control formControl"
            type="date"
            id="datePicker"
            value={datepickerVal}
            onChange={handleDatePicker}
          />
        </div>

        {/* Timepicker */}
        <div className="formGroup col-2">
          <label htmlFor="timePicker" className="">
            Default time input example
          </label>
          <input
            className="form-control formControl"
            type="time"
            id="timePicker"
            value={timepickerVal} // Bind the input value to the state
            onChange={handleTimePicker} // Call the handler on time change
          />
        </div>

        {/* Datetimepicker */}
        <div className="formGroup col-4">
          <label htmlFor="datetimePicker" className="">
            Select Date and Time
          </label>
          <input
            className="form-control formControl"
            type="datetime-local"
            id="datetimePicker"
            value={datetimeVal} // Bind the input value to the state
            onChange={handleDatetimePicker} // Call the handler on change
          />
        </div>

        {/* TIme Range Picker */}
        <div className="formGroup col-4">
          <label htmlFor="TimeRangePicker">Example Time Range Picker</label>
          <TimeRangePicker
            id="TimeRangePicker"
            onChange={setTimeRange}
            value={timeRange}
            className={"formControl"}
          />
        </div>

        {/* Date Range Picker */}
        <div className="formGroup col-4">
          <label htmlFor="DateRangePicker"> Example Date Range Picker</label>
          <DateRangePicker
            className={"formControl"}
            id="DateRangePicker"
            onChange={handleDateRangeChange}
            value={dateRange}
          />
        </div>

        {/* DateTimeRange */}
        <div className="formGroup col-4">
          <label htmlFor="DateRangePicker"> Example Date Range Picker</label>
          <DateTimePicker
            className={"formControl"}
            onChange={handleDateTimeRangeChange}
            value={dateTimeRange}
            format="y-MM-dd h:mm:ss a"
          />
        </div>

        {/* Select Menus */}

        {/* single select */}
        <div className="formGroup col-4">
          <label htmlFor="singleSelect">Single select examlpe</label>
          <Select
            options={options_xxxx}
            placeholder="Single select example"
            id="singleSelect"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
              }),
            }}
            className=""
            isSearchable
            // isDisabled
            isClearable
            value={singleselectedOption}
            onChange={handleSingleSelect}
          />
        </div>

        {/* multiple select */}
        <div className="formGroup col-4">
          <label htmlFor="multipleSelect">Multiple select example</label>
          <Select
            options={options_xxxx}
            placeholder="Multiple select example"
            id="multipleSelect"
            styles={{
              container: (provided) => ({
                ...provided,
                border: "1px solid #B0B0B0",
                borderRadius: "0px",
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                fontSize: "14px",
                maxHeight: "45px",
                overflow: "auto",
              }),
            }}
            className=""
            isSearchable
            isMulti
            isClearable
            value={multipleselectedOption}
            onChange={handleMultipleSelect}
          />
        </div>

        <div className=" formGroup col-4">
          <label htmlFor="exampleFormControlTextarea1" className="">
            TextArea example
          </label>
          <textarea
            className="form-control formControl"
            id="formControl"
            rows={10}
            placeholder={"address here"}
          />
        </div>

        {/* checks*/}
        <div className="checkboxes col-6 formGroup">
          <label
            htmlFor="flexCheckDefault"
            className="CheckboxLabel"
            style={{ fontSize: "14px" }}
          >
            Checkbox example
          </label>
          <div className="formGroup d-flex">
            <div className="form-check col-4">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id="flexCheckDefault"
              />
              <label className="form-check-label " htmlFor="flexCheckDefault">
                Default checkbox
              </label>
            </div>

            <div className="form-check col-4">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Default checkbox
              </label>
            </div>

            <div className="form-check formGroup col-4">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id="flexCheckDefault"
              />
              <label className="form-check-label " htmlFor="flexCheckDefault">
                Default checkbox
              </label>
            </div>
          </div>
        </div>

          {/* radios*/}      
          <div className="formGroup col-md-4">
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            Gender
          </label>
          <div className="radioGroup d-flex justify-content-start">
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="genderMale"
                value="male"
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Male
              </label>
            </div>
            <div className="form-check me-3">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="genderFemale"
                value="female"
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Female
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row btnGroup">
        <div className="col-4">
          <button type="button" className="btnCTA">
            FILTER
          </button>
        </div>
        <div className="col-4">
          <button type="button" className="btnCTA">
            RESET
          </button>
        </div>
        <div className="col-4">
          <button type="button" className="btnCTA">
            ADD CLIENT
          </button>
        </div>
      </div>

      <div
        className="resultsHeading"
        style={{ marginBottom: "10px", fontSize: "14px" }}
      >
        Results
      </div>
      <DataTable
        className="resultData"
        columns={columns}
        data={formData}
        pagination
        responsive
        id="myTable"
        customStyles={tableStyles}
      ></DataTable>
    </>
  );
}

export default Formtemplate;