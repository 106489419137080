import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const AddStaff = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_staffName: "",
    tb_staffType: "",
    tb_contactNumber: "",
    tb_emailAddress: "",
    tb_physicalAddress: "",
    tb_joiningDate: "",
    tb_dob: "",
    db_departmentName: "",
    db_designationName: "",
    tb_tin: "",
    tb_vrn: "",
  });

  const [error, setError] = useState("");

  const today = new Date().toISOString().split("T")[0];

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if(e.target.name === "tb_joiningDate" || e.target.name === "tb_dob"){
      const selectedDate = e.target.value;
      if (selectedDate > today) {
        setError("Date of cannot be a future date.");
      } else {
        setError("");
      }
    }
  };

  // Datetimepicker

  // Single select
  const [singleselectedOption, setsingleSelectedOption] = useState("");

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;
    if(singleselectedOption == null){
      setFormData({
        ...formData,
        [name]: "",
      });
    }
    else{
      setFormData({
        ...formData,
        [name]: singleselectedOption?.value,
      });
    }
  };

  const [departmentOptions, setdepartmentOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          "https://handler.optolinq.online/COMMON/getDepartmentList.php"
        ); // Replace with your API endpoint
        const options = response.data.map((option) => ({
          value: option.department_id, // Adjust based on your API response structure
          label: option.department_name, // Adjust based on your API response structure
        }));
        setdepartmentOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const [designationOptions, setdesignationOptions] = useState([]); // State to store "Created by" dropdown options

  useEffect(() => {
    // Fetch "Created by" options from API
    const fetchCreatedByOptions = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + '/COMMON/getDesignationList.php'
        ); // Replace with your API endpoint
        const options = response.data.map((option) => ({
          value: option.designation_id, // Adjust based on your API response structure
          label: option.designation_name, // Adjust based on your API response structure
        }));
        setdesignationOptions(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCreatedByOptions();
  }, []);

  const options_xxxx = [
    {
      value: "admin",
      label: "Admin",
    },
    {
      value: "normal",
      label: "Normal",
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/STAFF/addStaffHandler.php",
        // data: params,
        data: {
          tb_staffName: formData.tb_staffName,
          tb_clientName: formData.tb_clientName,
          tb_contactNumber: formData.tb_contactNumber,
          tb_secondaryNumber: formData.tb_secondaryNumber,
          tb_gender: formData.tb_gender,
          tb_emailAddress: formData.tb_emailAddress,
          tb_physicalAddress: formData.tb_physicalAddress,
          tb_poBox: formData.tb_poBox,
          tb_dob: formData.tb_dob,
          db_departmentName: formData.db_departmentName,
          db_designationName: formData.db_designationName,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then(function (response) {
          if (response.status == "200") {
            console.log(response);
            toast.success(response.data["success"], { position: "top-center" });
            navigate(`/Homepage/ViewClient`);
          }
          console.log(response.status);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === "401") {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else if (error.request) {
            console.error("Network Error:", error.request);
            toast.error("Network Error", error.request, {
              position: "top-center",
            });
          } else {
            console.log("Error:", error.message);
          }
        });
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE, { position: "top-center" });
    }
  };

  useEffect(()=>{
    console.log(formData)
  },[formData])
  return (
    <>
      <ToastContainer />
      {/* Heading and Sub Heading */}

      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row ">
          {/* String Fields */}
          <div className="col-md-12  formGroup">
            <label htmlFor="tb_staffName" className="">
              Staff Name
            </label>
            <input
              type="text"
              id="tb_staffName"
              name="tb_staffName"
              className="inputBox form-control formControl text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="singleSelect">Staff Type</label>
            <Select
              options={options_xxxx}
              placeholder="Staff Type"
              id="singleSelect"
              name="tb_staffType"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              isClearable
              value={singleselectedOption?.value}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="col-md-6  formGroup">
            <label htmlFor="tb_contactNumber" className="">
              Contact Number
            </label>
            <input
              type="number"
              id="tb_contactNumber"
              name="tb_contactNumber"
              className="inputBox form-control formControl  text-dark"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="col-12  formGroup">
            <label htmlFor="tb_emailAddress" className="">
              Email Address
            </label>
            <input
              type="email"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          {/* Datetimepicker */}
          <div className="formGroup col-6 formGroup">
            <label htmlFor="tb_dob" className="">
              Date Of Birth
            </label>
            <input
              className="form-control formControl"
              type="date"
              id="tb_dob"
              name="tb_dob"
              max={today}
              onChange={handleChange} // Call the handler on change
            />
          </div>
          {/* Datetimepicker */}
          <div className="formGroup col-6 formGroup">
            <label htmlFor="tb_joiningDate" className="">
              Joining Date
            </label>
            <input
              className="form-control formControl"
              type="date"
              id="tb_joiningDate"
              name="tb_joiningDate"
              max={today}
              onChange={handleChange} // Call the handler on change
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="db_departmentName">Department</label>
            <Select
              options={departmentOptions}
              placeholder="Department"
              id="db_departmentName"
              name="db_departmentName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              isClearable
              value={singleselectedOption}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="db_designationName">Designation</label>
            <Select
              options={designationOptions}
              placeholder="Designation"
              id="db_designationName"
              name="db_designationName"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable
              // isDisabled
              isClearable
              value={singleselectedOption?.value}
              onChange={handleSingleSelect}
            />
          </div>
          {/* Contact Number Field */}
          <div className="col-md-12 formGroup">
            <label htmlFor="tb_physicalAddress" className="">
              Physical Address
            </label>
            <textarea
              type="text"
              id="tb_physicalAddress"
              name="physicalAddress"
              className="inputBox form-control textarea  text-dark tb_address"
              
              cols="100"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_tin" className="">
              TIN
            </label>
            <input
              type="text"
              id="tb_tin"
              name="tb_tin"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_vrn" className="">
              VRN
            </label>
            <input
              type="text"
              id="tb_vrn"
              name="tb_vrn"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row btnGroup">
          <Link to={"/Homepage/SearchStaff"} className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnCancel"
            >
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddStaff;
