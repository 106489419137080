import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import AddPatient from "../Patients/AddPatient";
import Mainpopup from "../../../HomePage/Masters/Staff/EditStaffMainDetails";
import axios from "axios";
import { API_BASE_URL } from "../../../../optoCustom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const ViewStaff = () => {
  const { staffId } = useParams();
  const [formData, setFormData] = useState([]);
  const [mainDetails, setMainDetails] = useState({});
  const [historyDetails, setHistoryDetails] = useState([]);
  const [mainIsOpen, setmainIsOpen] = useState(false);
  const [isUser, setisUser] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "post",
          url: API_BASE_URL + "MASTER/STAFF/viewStaffDetailsHandler.php",
          data: {
            tb_staffId: staffId,
          },
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        });

        if (
          response.status === 200 &&
          response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
        ) {
          const responsedData = response.data.OPTO[0].OPTO_MESSAGE;
          console.log(response.data.OPTO[0].OPTO_MESSAGE);
          setFormData(responsedData);
          setMainDetails(responsedData.main_details[0]);
          setHistoryDetails(responsedData.history_details);

          if (responsedData.main_details[0].user_id != null) {
            setisUser(true);
          }

          // toast.success("Clients retrieved successfully!", { position: "top-center" });
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(
          error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
          { position: "top-center" }
        );
      }
    };

    fetchData();
  }, [staffId]);

  const toggleMainPopup = () => {
    setmainIsOpen(!mainIsOpen);
  };

  const handleButtonClick = async () => {
    try {
      const response = await axios({
        method: "post",
        url: API_BASE_URL + "MASTER/STAFF/staffConvertToUser.php",
        data: {
          tb_staffId: staffId,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      });

      if (
        response.status === 200 &&
        response.data.OPTO[0].OPTO_CODE_TYPE === "SUCCESS"
      ) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  };

  const historyAccordionColumns = [
    {
      name: "some column",
    },
  ];

  return (
    <div className="viewFormContainer row">
      <div className="formHeader d-flex align-items-center justify-content-between">
        <div className="headings">
          <div className="formHeading"></div>
        </div>
        <Link to={"/Homepage/SearchStaff"}>
          <button className="btnClose d-flex justify-content-center align-items-center mb-3">
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "#ffffff", height: "20px" }}
              className="mx-auto my-auto"
            />
          </button>
        </Link>
      </div>
      <hr />
      <div className="col-md-4">
        {isUser ? (
          <div>
            <button
              type="button"
              className="btnCTA"
              onClick={() => alert("Button 1 clicked!")}
            >
              Change Login Password
            </button>
            <button
              type="button"
              className="btnCTA"
              onClick={() => alert("Button 2 clicked!")}
            >
              Change Approval Password
            </button>
            <button
              type="button"
              className="btnCTA"
              onClick={() => alert("Button 3 clicked!")}
            >
              De-Activate User
            </button>
          </div>
        ) : (
          <button type="button" className="btnCTA" onClick={handleButtonClick}>
            Convert to User
          </button>
        )}
      </div>
      <div className="accordion col-12" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Main
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-md-12 formGroup">
                  <label htmlFor="tb_staffName" className="">
                    Staff Name
                  </label>
                  <input
                    type="text"
                    id="tb_staffName"
                    name="tb_staffName"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.staff_name || ""}
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_staffType" className="">
                    Staff Type
                  </label>
                  <input
                    type="text"
                    id="tb_staffType"
                    name="tb_staffType"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.staff_type || ""}
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_contactNumber" className="">
                    Contact Number
                  </label>
                  <input
                    type="number"
                    id="tb_contactNumber"
                    name="tb_contactNumber"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.contact_number || ""}
                  />
                </div>
                <div className="col-8 formGroup">
                  <label htmlFor="tb_emailAddress" className="">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="tb_emailAddress"
                    name="tb_emailAddress"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.email_address || ""}
                  />
                </div>
                <div className="col-md-4 formGroup">
                  <label htmlFor="tb_dob" className="">
                    Date Of Birth
                  </label>
                  <input
                    type="text"
                    id="tb_dob"
                    name="tb_dob"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.dob || ""}
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_joiningDate" className="">
                    Joining Date
                  </label>
                  <input
                    type="text"
                    id="tb_joiningDate"
                    name="tb_joiningDate"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.joining_date || ""}
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_lastDate" className="">
                    Last Date
                  </label>
                  <input
                    type="text"
                    id="tb_lastDate"
                    name="tb_lastDate"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.last_date || ""}
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_department" className="">
                    Department
                  </label>
                  <input
                    type="text"
                    id="tb_department"
                    name="tb_department"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.department_id || ""}
                  />
                </div>
                <div className="col-md-6 formGroup">
                  <label htmlFor="tb_designation" className="">
                    Designation
                  </label>
                  <input
                    type="text"
                    id="tb_designation"
                    name="tb_designation"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.designation_id || ""}
                  />
                </div>
                <div className="col-md-12 formGroup">
                  <label htmlFor="tb_physicalAddress" className="">
                    Physical Address
                  </label>
                  <textarea
                    type="text"
                    id="tb_physicalAddress"
                    name="tb_physicalAddress"
                    className="inputBox form-control text-dark tb_address"
                    cols="100"
                    readOnly
                    value={mainDetails.physical_address || ""}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_tin" className="">
                    TIN
                  </label>
                  <input
                    type="text"
                    id="tb_tin"
                    name="tb_tin"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.tin || ""}
                  />
                </div>
                <div className="formGroup col-md-6">
                  <label htmlFor="tb_vrn" className="">
                    VRN
                  </label>
                  <input
                    type="text"
                    id="tb_vrn"
                    name="tb_vrn"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.vrn || ""}
                  />
                </div>
                <div className="formGroup col-md-12">
                  <label htmlFor="tb_signature" className="">
                    Digital Signature
                  </label>
                  <input
                    type="text"
                    id="tb_signature"
                    name="tb_signature"
                    className="inputBox form-control  text-dark"
                    readOnly
                    value={mainDetails.digital_signature || ""}
                  />
                </div>
              </div>
              <div className="row btnGroup">
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn_edit col-12"
                    onClick={toggleMainPopup}
                  >
                    Edit Main Details
                  </button>
                  <Mainpopup
                    trigger={mainIsOpen}
                    setTrigger={setmainIsOpen}
                    staffData={mainDetails}
                    value={mainDetails.staffId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              History
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <DataTable
                columns={historyAccordionColumns}
                data={historyDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStaff;
