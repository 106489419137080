import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_BASE_URL } from "../../../../optoCustom.js";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
const AddSuppliers = () => {
  const navigate = useNavigate();
  // State to handle form data
  const [formData, setFormData] = useState({
    tb_supplier: "",
    tb_shortName: "",
    tb_supplierType: "",
    tb_tin: "",
    tb_vrn: "",
    tb_physicalAddress: "",
    tb_postBox: "",
    tb_city: "",
    tb_country: "",
    tb_primaryNumber: "",
    tb_secondaryNumber: "",
    tb_emailAddress: "",
    tb_contactName: "",
    tb_designation: "",
  });

  // Handle input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  // Single select
  const [singleselectedOption, setsingleSelectedOption] = useState("");

  const handleSingleSelect = (singleselectedOption, actionMeta) => {
    console.log(singleselectedOption?.value);
    const { name } = actionMeta;

    // Reset the field if no option is selected
    if (singleselectedOption == null) {
      setFormData({
        ...formData,
        [name]: "",
      });
      // Disable the country input when no selection is made
      document.querySelector("#tb_country").disabled = true;
    } else {
      // Enable/Disable based on the selection
      if (singleselectedOption.value.toLowerCase() === "local") {
        document.querySelector("#tb_country").disabled = true;
        console.log("triggered");
      } else if (singleselectedOption.value.toLowerCase() === "foreign") {
        document.querySelector("#tb_country").disabled = false;
      }

      // Update form data with the selected option
      setFormData({
        ...formData,
        [name]: singleselectedOption.value,
      });
    }
  };

  const options_xxxx = [
    {
      value: "Local",
      label: "Local",
    },
    {
      value: "Foreign",
      label: "Foreign",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData.tb_clientName);
      console.log(formData.tb_country);
      axios({
        method: "post",
        url: API_BASE_URL + "MASTER/SUPPLIER/addSupplierHandler.php",
        // data: params,
        data: {
          tb_supplier: formData.tb_supplier,
          tb_shortName: formData.tb_shortName,
          tb_supplierType: formData.tb_supplierType,
          tb_tin: formData.tb_tin,
          tb_vrn: formData.tb_vrn,
          tb_secondaryNumber: formData.tb_secondaryNumber,
          tb_primaryNumber: formData.tb_primaryNumber,
          tb_emailAddress: formData.tb_emailAddress,
          tb_physicalAddress: formData.tb_physicalAddress,
          tb_contactName: formData.tb_contactName,
          tb_postBox: formData.tb_postBox,
          tb_city: formData.tb_city,
          tb_country: formData.tb_country,
          tb_contactdesignation: formData.tb_designation,
        },
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
      })
        .then(function (response) {
          if (response.status == "200") {
            console.log(response);
            toast.success(response.data["success"], { position: "top-center" });
            navigate(`/Homepage/ViewSuppliers`);
          }
          console.log(response.status);
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === "401") {
              toast.error(error.response.data["error"], {
                position: "top-center",
              });
            } else {
              toast.error(error.message, { position: "top-center" });
            }
          } else if (error.request) {
            console.error("Network Error:", error.request);
            toast.error("Network Error", error.request, {
              position: "top-center",
            });
          } else {
            console.log("Error:", error.message);
          }
        });
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.data.OPTO[0].OPTO_CODE + error.data.OPTO[0].OPTO_MESSAGE,
        { position: "top-center" }
      );
    }
  };
  return (
    <>
      <ToastContainer />
      {/* Heading and Sub Heading */}
      
      {/* inputs */}

      {/* Text Input Label-above */}
      <form onSubmit={handleSubmit} className="inputContent">
        <div className="row mt-1">
          <div className="formGroup col-md-12 mt-1">
            <label htmlFor="tb_supplier" className="">
              Supplier Name
            </label>
            <input
              type="text"
              id="tb_supplier"
              name="tb_supplier"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_shortName" className="">
              Short Name
            </label>
            <input
              type="text"
              id="tb_shortName"
              name="tb_shortName"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-6">
            <label htmlFor="db_supplierType" className="">
              Supplier Type
            </label>
            <Select
              options={options_xxxx}
              placeholder="Supplier Type"
              id="db_supplierType"
              name="db_supplierType"
              styles={{
                container: (provided) => ({
                  ...provided,
                  border: "1px solid #B0B0B0",
                  borderRadius: "0px",
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0px",
                  fontSize: "14px",
                  maxHeight: "45px",
                }),
              }}
              className=""
              isSearchable={false}
              // isDisabled
              isClearable
              value={singleselectedOption?.value}
              onChange={handleSingleSelect}
            />
          </div>
          <div className="formGroup col-md-12">
            <label htmlFor="tb_physicalAddress" className="">
              Physical Address
            </label>
            <input
              type="text"
              id="tb_physicalAddress"
              name="tb_physicalAddress"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_poBox" className="">
              P.O.Box
            </label>
            <input
              type="text"
              id="tb_poBox"
              name="tb_poBox"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_city" className="">
              City
            </label>
            <input
              type="text"
              id="tb_city"
              name="tb_city"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-4">
            <label htmlFor="tb_country" className="">
              Country
            </label>
            <input
              type="text"
              id="tb_country"
              name="tb_country"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
              disabled={true}
            />
          </div>
          <div className="formGroup col-md-12">
            <label htmlFor="tb_emailAddress" className="">
              Email Address
            </label>
            <input
              type="text"
              id="tb_emailAddress"
              name="tb_emailAddress"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_contactName" className="">
              Contact Person
            </label>
            <input
              type="text"
              id="tb_contactName"
              name="tb_contactName"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_contactdesignation" className="">
              Designation
            </label>
            <input
              type="text"
              id="tb_contactdesignation"
              name="tb_contactdesignation"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-6">
            <label htmlFor="tb_contactNumber" className="">
              Primary Contact Number
            </label>
            <input
              type="text"
              id="tb_contactNumber"
              name="tb_contactNumber"
              className="inputBox form-control formControl  text-dark"
              maxLength="12"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-3">
            <label htmlFor="tb_tin" className="">
              TIN
            </label>
            <input
              type="text"
              id="tb_tin"
              name="tb_tin"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
          <div className="formGroup col-md-3">
            <label htmlFor="tb_vrn" className="">
              VRN
            </label>
            <input
              type="text"
              id="tb_vrn"
              name="tb_vrn"
              className="inputBox form-control formControl  text-dark"
              onChange={handleChange}
            />
          </div>
        </div>
        <br />
        <div className="row btnGroup">
          <Link to={"/Homepage/SearchSuppliers"}className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnCancel"
            >
              Cancel
            </button>
          </Link>
          <div className="col-md-4">
            <button
              type="reset"
              className="btnCTA btnReset"
              onClick={() => window.location.reload()}
            >
              Reset
            </button>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="btnCTA btnAddSave"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddSuppliers;
