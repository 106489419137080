import React from 'react';
import comingsoonimg from "./../../Assets/comingsoonplaceholder.png"

const Dashboard = () => {
  return (
    <div
    style={
      {
        height: "100vh",
    backgroundImage: `url(${comingsoonimg})`,
    backgroundSize: 'cover',        // Makes the image cover the entire div, cropping if necessary
    backgroundPosition: 'center',   // Centers the image within the div
    backgroundRepeat: 'no-repeat'
      }
    }
    >

    </div>
  )
}

export default Dashboard
